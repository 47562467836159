import { Table, InputGroup, Button, Dropdown, Form } from "react-bootstrap";
import { CSVLink } from "react-csv";
import printJS from "print-js";
import {
  CalendarIcon,
  CubeIcon,
  DeleteIcon,
  EditIcon,
  ExportIcon,
  FIleUploadLineIcon,
  FilterCollapseIcon,
  PrintIcon,
  RecieptIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import DateRangePicker from "../utils/DateRangePicker";
import CachedIcon from "mdi-react/CachedIcon";
import { FilterTwoIcon, PDFIcon, ExcelIcon } from "../Icons";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import useDebounce, {
  useChosenBackendUrl,
  useEffectOnce,
  useIsAdmin,
  useIsCashier,
  useIsStore,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import queryString from "query-string";
import { appSettings, backendApis, services } from "../../config";
import { useMutation, useQuery, useQueryClient } from "react-query";
import currency from "currency.js";
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  compareAsc,
  format,
  parse,
} from "date-fns";
import ReactPaginate from "react-paginate";
import {
  defaultSelectValue,
  fetchActionsUtil,
  formatDate,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  scrollToTop,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import UpdateItemModal from "../UpdateItemModal";
import NewItemModal from "../NewItemModal";
import PurchaseDialog from "../PurchaseDialog";
import NewItemServiceModal from "../NewItemServiceModal";
import NewItemModalWrapper from "../NewItemModalWrapper";
import NewVendorModal from "../NewVendorModal";
import BatchesModal from "./BatchesModal";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import LoginDialog from "../LoginDialog";
import ConfirmDialog from "../ConfirmDialogue";
import { toast } from "react-toastify";
import CubeOutline from "mdi-react/CubeOutlineIcon";
import { Link } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import Select from "react-select";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import ItemsTable from "../utils/ItemsTable";
import TableComponent from "../TableComponent";
import ConvertQuantity from "../utils/ConvertQuantity";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import UpdateItemPriceModal from "../UpdateItemPriceModal";
import QuantityConversionModal from "../QuantityConversionModal";
import GRNDialog from "../GRNDialog";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty } from "lodash";
import CurrencyInput from "react-currency-input-field";

const CompanySwitcher = () => {
  const backendUrl = useChosenBackendUrl();
  const { user: authUser } = useAuth();

  const options = backendApis
    .map((el) => ({
      ...el,
      value: el.name,
      label: el.name,
    }))
    .filter((el) => el?.isHq);
  // .filter((el) => el.value !== authUser.company);

  const setSelectedCompanyForAnalytics = useStoreActions(
    (actions) => actions.setSelectedCompanyForAnalytics
  );
  const selectedCompanyForAnalytics = useStoreState(
    (state) => state.selectedCompanyForAnalytics
  );
  const selectedCompany = useMemo(() => {
    return options.find((el) =>
      selectedCompanyForAnalytics?.value
        ? el.value === selectedCompanyForAnalytics?.value
        : el.url === backendUrl
    );
  }, [options, backendUrl, selectedCompanyForAnalytics]);

  useEffectOnce(() => {
    setSelectedCompanyForAnalytics(options[0]);
  });

  return (
    <div
      className="d-flex gap-1 align-items-center"
      style={{ marginTop: "-0.25rem" }}
    >
      <Dropdown style={{ margin: 0 }}>
        <Dropdown.Toggle
          variant=""
          className="bg-light-blue text-primary pe-2"
          bsPrefix="change"
        >
          {selectedCompany?.value}
          {"    "}
          <span className="d-inline  me-n2">
            <ChevronDownIcon />
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu
          popperConfig={{
            strategy: "fixed",
          }}
          renderOnMount
          className=""
        >
          {options.map((el, index) => (
            <Dropdown.Item
              key={index}
              as="button"
              className={`${
                el.value === selectedCompany?.value ? "active" : ""
              }`}
              onClick={() => setSelectedCompanyForAnalytics({ ...el })}
            >
              {el.value}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default function ItemsExpectedReport() {
  const queryClient = useQueryClient();
  const { isIronRod, isCement, user: authUser } = useAuth();
  const backendUrl = useChosenBackendUrl();

  const mainBackendUrl = useAuth();

  const generalSettings = useStoreState((state) => state.generalSettings);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showUpdatePriceModal, setShowUpdatePriceModal] = useState(false);
  useScrollTop();

  const [showUpdateInventoryModal, setShowUpdateInventoryModal] = useState(
    false
  );
  const [showBatchesModal, setShowBatchesModal] = useState(false);
  const [
    showCreateNewInventoryModal,
    setShowCreateNewInventoryModal,
  ] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const initialFilterParams = useMemo(() => {
    try {
      return {
        page: 1,
        limit: 40,
        barcode: "",
        itemName: "",
        issueTo: authUser.company,
      };
    } catch (err) {
      console.log(err);
      return {};
    }
  }, [generalSettings]);

  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    ...initialFilterParams,
  });

  const [excelData, setExcelData] = useState([]);

  const [filterData, setFilterData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchItems = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/items/expected-items?${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = { count: 0, items: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [queryActions.ITEMS_OUT_OF_STOCK, queryParams],
    () => fetchItems(queryParams),
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    const element = data?.items?.map((el) => ({ ...el, mainQty: el.quantity }));
    const filtered = data?.items?.filter((el) => parseInt(el.remaining) !== 0);
    setFilterData(filtered);
    setTableData(element);
  }, [data?.items]);

  const handleInputChange = ({ index, name, value }) => {
    const oldTableData = tableData;
    oldTableData[index][name] = value;
    setTableData([...oldTableData]);
  };

  const onSelected = (item) => {
    const theSelected = [...selectedData];
    if (theSelected.length > 0) {
      const index = theSelected.findIndex((it) => it.id === item.id);

      if (index >= 0) {
        theSelected.splice(index, 1);
        setSelectedData(theSelected);
      } else {
        setSelectedData((d) => [...d, item]);
      }
    } else {
      setSelectedData((d) => [...d, item]);
    }
  };

  const selectAll = (e) => {
    if (!e.target.checked) {
      setSelectedData([]);
    } else {
      const element = tableData?.filter((el) => parseInt(el.remaining) !== 0);
      setSelectedData(element);
    }
  };

  const deleteItem = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/delete`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteMutation = useMutation((payload) => deleteItem(payload), {
    onSuccess: ({ message, data: { item } }) => {
      toast.success(message);

      queryClient.setQueryData(
        [queryActions.ITEMS_OUT_OF_STOCK, queryParams],
        (data) => {
          data.items = data.items.filter((el) => el.Bar_Code !== item.Bar_Code);
          return data;
        }
      );
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const handleDeleteItem = async (item) => {
    if (await LoginDialog()) {
      if (
        await ConfirmDialog({
          title: "Delete Item",
          description:
            "Are you sure you want to delete this Item from your inventory",
        })
      ) {
        deleteMutation.mutate(item);
      }
    }
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams({
      ...initialFilterParams,
    });
  };

  const handleSelectedItem = (item) => {
    setFilterParams({
      ...filterParams,
      barcode: item.Bar_Code,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };

  const editPrice = async (el) => {
    if (await LoginDialog()) {
      setSelectedItem(el);
      setShowUpdatePriceModal(true);
    }
  };

  const printGRN = async (InvoiceNo) => {
    await GRNDialog({
      title: "Transaction posted successfully",
      // description: "...",
      InvoiceNo,
    });
  };

  const ReceiveMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/items/sender-end-items`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: () => {
        toast.success(`Success`);
        refetch();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  // The main table data
  const tableBodyData = (el, index) => {
    const color =
      compareAsc(new Date(), new Date(el?.ExpireDate)) === 1
        ? { borderLeft: "5px solid red" }
        : { borderLeft: "5px solid inherit" };

    return (
      <>
        {/* <td style={color} key={el?.Bar_Code}>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="bg-white border-0"
              bsPrefix="print more"
            >
              <DotsVerticalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            />
          </Dropdown>
        </td> */}
        <td style={color} key={el?.Bar_Code}>
          <input
            type="checkbox"
            onChange={() => onSelected(el)}
            checked={Boolean(selectedData.find((ek) => ek.id === el.id))}
            disabled={parseInt(el.remaining) === 0}
          />
        </td>
        <td>{data?.startIndex + index + 1}</td>
        <td>{el?.barcode}</td>
        <td>{el?.itemName}</td>
        <td>
          {parseInt(el.remaining) === 0 ? (
            <ConvertQuantity quantity={el.quantity} desc={el?.Item_Desc} />
          ) : (
            <CurrencyInput
              className="form-control border-0 px-1 w-70"
              value={el.quantity}
              onKeyDown={(e) => e.keyCode === 13 && e.target.blur()}
              name="quantity"
              onValueChange={(value, name) =>
                handleInputChange({
                  index,
                  name,
                  value,
                })
              }
              //  disableGroupSeparators
              allowNegativeValue={false}
              allowDecimals={false}
              //   disabled={selectedItems}
              width={70}
            />
          )}
        </td>
        <td>
          <ConvertQuantity
            quantity={currency(el?.mainQty).subtract(el?.remaining).value}
            desc={el?.Item_Desc}
          />
        </td>
        <td>
          <ConvertQuantity quantity={el.remaining} desc={el?.Item_Desc} />
        </td>
        <td>{el?.Sendercompany}</td>
        <td>{el?.receivingofficer}</td>
        <td>{el?.Date_Log ? formatDate(el?.Date_Log, "dd MMM, yyyy") : ""}</td>
      </>
    );
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          {/* <th /> */}
          <th>
            <input
              type="checkbox"
              onChange={selectAll}
              checked={Boolean(selectedData.length === filterData.length)}
            />
          </th>
          <th>S/N</th>
          <th>Item Code</th>
          <th>Item Name</th>
          <th>QTY</th>
          <th>Supplied QTY</th>
          <th>Remaining QTY</th>
          <th>Sent By</th>
          <th>Receiving Officer</th>
          <th>Date</th>
        </tr>
      </thead>
    );
  };

  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    const { limit, page, ...rest } = queryParams;
    let exData = await fetchExcelData(
      `${backendUrl}/api/items?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;

    // console.log(exData.data);
    exData = exData.data.items.map((row) => ({
      ...row,
      Date_Log: format(new Date(row.Date_Log), "dd-MMM-yyyy"),
    }));

    exData = exData.map((d, i) => [
      data?.startIndex + i + 1,
      d.Bar_Code,
      d.ItemName,
      d.Item_Desc,
      isIronRod
        ? qtyFormatToString(
            qtyFormat(d.Quantity, d.Item_Desc, itemMeasurements)
          )
        : d.Quantity,
      currency(d?.UnitCost, {
        symbol: "",
      }).format(),
      currency(d?.UnitPrice, {
        symbol: "",
      }).format(),
      d.Date_Log,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Inventory Entry Report"],
      [date],
      [""],
      [
        "S/N",
        "Item Code",
        "Item Name",
        "Item Desc",
        "QTY",
        "Unit Cost",
        "Unit Price",
        "Post time",
      ],
      ...exData,
      [""],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const receiveItems = async () => {
    if (selectedData.length < 1) {
      return toast.error(`Select an Item`);
    }

    const errors = selectedData.filter((el) => el.quantity > el.remaining);
    if (errors.length > 0) {
      return toast.error(
        `Input a lower quantity for ${errors
          .map((el) => el.itemName)
          .join(",")}`
      );
    }
    const ele = {
      selectedData,
      sender: `${mainBackendUrl?.backendUrl}/api/items/receivers-end-items`,
    };
    console.log(ele);
    if (
      await ConfirmDialog({
        title: "Receive Item(s)",
        description:
          "Are you sure you want to receive this Item(s) to your inventry",
      })
    ) {
      //console.log("selectedItems => ", all);
      ReceiveMutation.mutate(ele);
    }
  };

  return (
    <section className="cashbook-wrapped">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Code</Form.Label>

                      <InputGroup>
                        <Form.Control
                          name="barcode"
                          placeholder="Enter Item Code"
                          value={filterParams?.barcode}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />

                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          content={() => (
                            <ItemsTable
                              handleSelectedItem={handleSelectedItem}
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() =>
                              setShowItemsPopover(!showItemsPopover)
                            }
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Name</Form.Label>
                      <Form.Control
                        name="itemName"
                        value={filterParams?.itemName}
                        placeholder="Enter Item Name"
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </Form.Group>
                    <hr className="mt-3 mb-4" />
                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="topStart"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                  parse(
                                    filterParams.startDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                  parse(
                                    filterParams.endDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Items Expected Report
                  <button
                    onClick={() => refetch()}
                    className="btn text-primary"
                    title="Refresh"
                  >
                    <CachedIcon />
                  </button>
                </h1>
                <div className="actions mr-5">
                  <div>
                    <button className="btn btn-primary" onClick={receiveItems}>
                      {" "}
                      Receive Item
                    </button>
                  </div>
                  <CompanySwitcher />
                </div>
              </header>

              <div className="px-md-4">
                <TableComponent
                  responsive
                  borderless
                  striped
                  tableHeadsFunction={tableHead}
                  mainDataArray={tableData}
                  tableDataRowFunction={tableBodyData}
                  className="product-table text-nowrap"
                />{" "}
                {!isFetching && isSuccess && isEmpty(tableData) ? (
                  <NoTableItem queryParams={queryParams} />
                ) : null}
              </div>

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data.count / queryParams.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    scrollToTop();
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>
            </div>
          </div>
        </div>

        {showUpdateInventoryModal && (
          <UpdateItemModal
            showUpdateInventoryModal={showUpdateInventoryModal}
            setShowUpdateInventoryModal={setShowUpdateInventoryModal}
            refetch={refetch}
            printGRN={printGRN}
          />
        )}

        {showCreateNewInventoryModal && (
          <NewItemModalWrapper
            setShowCreateNewInventoryModal={setShowCreateNewInventoryModal}
            showCreateNewInventoryModal={showCreateNewInventoryModal}
            refetch={refetch}
          />
        )}

        {showBatchesModal && selectedItem && (
          <BatchesModal
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            showBatchesModal={showBatchesModal}
            setShowBatchesModal={setShowBatchesModal}
          />
        )}

        {showUpdatePriceModal && selectedItem ? (
          <UpdateItemPriceModal
            showUpdatePriceModal={showUpdatePriceModal}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            setShowUpdatePriceModal={setShowUpdatePriceModal}
            refetch={refetch}
          />
        ) : null}

        <ModalLoader show={isfetchingExcel || ReceiveMutation.isLoading} />

        {/*  <QuantityConversionModal /> */}
      </main>
    </section>
  );
}
