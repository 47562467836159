import { useStoreState } from "easy-peasy";
import React from "react";
import { Logo } from "./Icons";
import { NotSubscribed } from "./utils/NotSubscribed";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useIsAdmin } from "../utils/hooks";

export default function DisplayChildElement({ children, label, searchText }) {
  const savedStaffRoles = useStoreState((state) => state.savedStaffRoles);

  return label.toLowerCase().includes(searchText.toLowerCase()) && children;
}

export const IsPrivileged = ({ children, roleName }) => {
  const savedStaffRoles = useStoreState((state) => state.savedStaffRoles);
  const adminRootRoles = useStoreState((state) => state.rootRoles);
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();

  let privilege = isAdmin ? adminRootRoles : savedStaffRoles;
  const checkPrivilege = privilege.find((role) => {
    return role.name.toLowerCase() === roleName.toLowerCase() && role.checked;
  });

  if (checkPrivilege) {
    // console.log(children);
    return children;
  } else {
    return (
      <div
        className="vw-100 d-flex align-items-center justify-content-center text-center "
        style={{ height: "60vh" }}
      >
        <div>
          <Logo />
          <h4 className="display-5"> Unauthorized</h4>{" "}
          <Button
            onClick={() => navigate(-1)}
            variant=""
            className="text-primary text-underline"
          >
            Go back
          </Button>
        </div>
      </div>
    );
  }
};
