import {
  Button,
  Form,
  Table,
  Overlay,
  // Popover,
  Dropdown,
  InputGroup,
} from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  CalendarIcon,
  ExportIcon,
  FilePdfIcon,
  FilterCollapseIcon,
  FilterIcon,
  PrintIcon,
  ExcelIcon,
  PDFIcon,
  RecieptIcon,
  DeleteIcon,
  EditIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import CachedIcon from "mdi-react/CachedIcon";
import { useRef, useState, useEffect } from "react";
import {
  FilterTwoIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
} from "../Icons";
import useDebounce, {
  useIsAdmin,
  useLocalStorage,
  useQueryParams,
} from "../../utils/hooks";
import { services } from "../../config";
import queryString from "query-string";
import { useMutation, useQuery } from "react-query";
import { reportActions } from "../../utils/reactQueryActions";
import { format, parse } from "date-fns";
import {
  customerFullName,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
} from "../../utils/helpers";
import ReactPaginate from "react-paginate";
import currency from "currency.js";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import DatePickerCustomInput from "./../utils/DatePickerCustomInput";
import CustomerSelectorPopover from "../utils/CustomerSelectorPopover";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { isEmpty, isNull, map } from "lodash";
import { useAuth } from "../../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import NoTableItem from "../utils/NoTableItem";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import ConvertQuantity from "../utils/ConvertQuantity";
import VendorSelector from "../Vendor/VendorSelector";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { toast } from "react-toastify";
import SelectItemUnitsDialog from "../SelectUnitsDialog";
import ConfirmDialog from "../ConfirmDialogue";
import { fetchActionsUtil } from "../../utils/helpers";
import EditPurchaseHistoryModal from "../EditPurchaseHistory";
import GRNDialog from "../GRNDialog";
import moment from "moment";
import { IsPrivileged } from "../DisplayChildElement";

function PurchaseHistory() {
  const reportTypeOptions = [
    {
      value: "Simple",
      label: "Simple Report",
    },
    {
      value: "Detailed",
      label: "Detailed",
    },
  ];
  const initialFilterParams = {
    reportType: "Simple",
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    searchInDate: true,
    invoiceNumber: "",
    modelNumber: "",
    vendorNumber: "",
  };

  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl, isIronRod } = useAuth();
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [showCustomerSelector, setShowCustomerSelector] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);
  const isAdmin = useIsAdmin();
  const [purchaseHistoryToEdit, setPurchaseHistoryToEdit] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [
    showEditPurchaseHistoryModal,
    setShowEditPurchaseHistoryModal,
  ] = useState(false);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,

    // Get the date in url and parse so date picker can use it
    date: queryParams?.date
      ? parse(queryParams?.date, "yyyy-MM-dd", new Date())
      : "",
    CompName: queryParams?.CompName ? queryParams?.CompName : "",
    startDate: queryParams?.NoDate ? "" : format(new Date(), "yyyy-MM-dd"),
    endDate: queryParams?.NoDate ? "" : format(new Date(), "yyyy-MM-dd"),
  });

  const debouncedFilterParams = useDebounce(filterParams, 400);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchPurchaseHistory = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/purchase-history?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.branch = [
      {
        label: "All",
        value: "",
      },
      ...data.branch
        .map((el) => ({
          label: el.Branch,
          value: el.Branch,
        }))
        .filter((el) => Boolean(el.value)),
    ];

    return data;
  };

  const {
    data = {
      count: 0,
      purchaseHistory: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.PURCHASE_HISTORY, queryParams],
    () => fetchPurchaseHistory(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/purchase-history?${queryString.stringify(
        rest
      )}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;

    exData = exData.data.purchaseHistory.map((d, i) => [
      i + 1,
      d.InvoiceNo,
      d.Bar_Code,
      d.ItemName,
      d.Vendor_ID,
      isIronRod
        ? qtyFormatToString(
            qtyFormat(d.Quantity, d.item.Item_Desc, itemMeasurements)
          )
        : d.Quantity,
      d.UnitPrice,
      d.AmountDue,
      d.PurchaseID,
      d.PurchaseDate ? format(new Date(d.PurchaseDate), "dd MMM yyyy") : "...",
      d.Username,
      d.Vendor_ID,
      d.DriverName,
      d.DriverPhoneNumber,
      d.TruckNumber,
      d.DeliveryDate ? format(new Date(d.DeliveryDate), "dd MMM, yyyy") : "...",
    ]);

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    exData = [
      [company],
      ["Purchase History Report"],
      [date],
      [""],
      [
        "S/N",
        "Invoice No",
        "Item Code",
        "Item Name",
        "Vendor",
        "QTY Purchased",
        "Unit Cost",
        "Amount",
        "Purchase ID",
        "Purchase Date",
        "Username",
        "Vendor ID",
        "Driver Name",
        "Truck Number",
        "Phone Number",
        "Delivery Date",
      ],
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Amount Due",
        currency(data.amountDue, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Amount Paid",
        currency(data.amountPaid, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Balance",
        currency(data.balance, {
          symbol: "",
        }).format(),
      ],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectedCustomer = (customer) => {
    setSelectedCustomer(customer);
    setFilterParams({
      ...filterParams,
      vendorNumber: customer.Vendor_ID,
    });
    setShowItemsPopover(false);
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,

      // format the date, before setting it in the url
      date: filterParams?.date
        ? format(new Date(filterParams?.date), "yyyy-MM-dd")
        : "",
    });
  };

  const reset = () => {
    setSelectedCustomer();
    setFilterParams(initialFilterParams);
    setTimeout(() => {
      setQueryParams({
        ...queryParams,
        ...initialFilterParams,
      });
    }, 0);
  };

  const deletePurchaseApi = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/transaction/delete-purchase`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deletePurchaseMutation = useMutation(
    (payload) => deletePurchaseApi(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        refetch();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const deleteWarehousePurchaseApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/warehouse/delete-purchase`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteWarehousePurchaseMutation = useMutation(
    (payload) => deleteWarehousePurchaseApi(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        refetch();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const deletePurchase = async (el) => {
    if (
      await ConfirmDialog({
        title: "Delete Purchase",
        description: "Are you sure you want to delete this purchase",
      })
    ) {
      let saleType = el?.saleType;
      // for old records so we  need unit so we can calculate the value
      if (!el.saleType) {
        const foundMeasurement = itemMeasurements.find(
          (el) => el.Size === el?.item?.Item_Desc
        );
        saleType = await SelectItemUnitsDialog({
          //  we  have  to  assume ------------------
          saleType: foundMeasurement ? "Tons" : "Each",
        });
      }

      if (saleType) {
        if (el?.type === "warehouse") {
          deleteWarehousePurchaseMutation.mutate({
            ...el,
            saleType,
          });
        } else {
          deletePurchaseMutation.mutate({
            ...el,
            saleType,
          });
        }
      }
    }
  };
  const getPurchase = async ({ InvoiceNo }) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${backendUrl}/api/vendors/purchase/${InvoiceNo}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        let {
          data: { purchasedItems },
        } = await response.json();
        setPurchaseHistoryToEdit(
          purchasedItems.map((el) => ({
            ...el.item,
            ...el,
            UnitPrice: el.item.UnitPrice,
            PurchasePrice: el.UnitPrice,
            Item_Name: el.ItemName,
            vendorName: el.vendor?.CompanyName,
            Unit: el.saleType,
            InvoiceAmount: el.AmountDue,
            Vendor: el.vendor.Vendor_ID,
            selectedBatches: [el.purchaseBatchItem],
            batchHasBeenSold:
              Number(el.purchaseBatchItem.originalQuantity) !=
              Number(el.purchaseBatchItem.Quantity),
            // ---------------------------------------------------------
            Batch_Name: el.purchaseBatchItem.Batch_Name,
            parentBatchBarCode: el.purchaseBatchItem.parentBatchItemName,
            parentBatchItemName: el.purchaseBatchItem.parentBatchItemName,
            parentBatchName: el.purchaseBatchItem.parentBatchName,
            DriverName: el.purchaseBatchItem.DriverName,
            DriverPhoneNumber: el.purchaseBatchItem.DriverPhoneNumber,
            TruckNumber: el.purchaseBatchItem.TruckNumber,
            DeliveryDate: moment(new Date(el.purchaseBatchItem.DeliveryDate)),
            ManDate: moment(new Date(el.purchaseBatchItem.ManDate)),
            ExpireDate: moment(new Date(el.purchaseBatchItem.ExpireDate)),
          }))
        );
        setShowEditPurchaseHistoryModal(true);
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to load Purchase, Try again");
    } finally {
      setIsLoading(false);
    }
  };

  const printGRN = async (InvoiceNo) => {
    await GRNDialog({
      title: "Purchase Edited successfully",
      // description: "...",
      InvoiceNo,
    });
  };

  const editPurchase = ({ InvoiceNo }) => {
    getPurchase({ InvoiceNo: InvoiceNo });
  };

  // isprivileged

  return (
    <IsPrivileged roleName="Purchase History">
    <main className="cash-book">
      <div className="content">
        <div className="content-main">
          <div className={`filter-area ${showFilter ? "open" : "close"}`}>
            <div className="filter-header">
              <h2>
                <FilterTwoIcon /> Filters
              </h2>

              <Button
                variant="white"
                onClick={() => setShowFilter(!showFilter)}
              >
                <FilterCollapseIcon />
              </Button>
            </div>
            <div className="filter-body">
              <Form>
                <div className="body">
                  <Form.Group className="mb-2-5">
                    <Form.Label>Report Type</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="All"
                      isSearchable={false}
                      theme={reactSelectTheme}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          reportType: selected.value,
                        });
                      }}
                      defaultValue={
                        queryParams?.reportType
                          ? reportTypeOptions.find(
                              (el) => el.value === queryParams?.reportType
                            )
                          : reportTypeOptions[0]
                      }
                      options={reportTypeOptions}
                    />
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Branch</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      name="branch"
                      placeholder="All"
                      theme={reactSelectTheme}
                      isSearchable={true}
                      key={data?.branch}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          branch: selected.value,
                        });
                      }}
                      defaultValue={
                        filterParams?.branch && data?.branch
                          ? data?.branch.find(
                              (el) => el.value === filterParams?.branch
                            )
                          : {
                              value: "",
                              label: "All",
                            }
                      }
                      options={data?.branch}
                    />
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Invoice No.</Form.Label>
                    <Form.Control
                      name="invoiceNumber"
                      defaultValue={filterParams?.invoiceNumber}
                      onChange={(e) => handleFilterParamsChange(e)}
                      placeholder="Input Invoice No."
                    />
                  </Form.Group>

                  <hr className="my-3" />

                  <Form.Group className="mb-2-5">
                    <Form.Label>Model No.</Form.Label>
                    <Form.Control
                      name="modelNumber"
                      defaultValue={filterParams?.modelNumber}
                      onChange={(e) => handleFilterParamsChange(e)}
                      placeholder="Input Model No."
                    />
                  </Form.Group>

                  <hr className="my-3" />

                  {/* <Form.Group className="mb-2-5">
                    <Form.Label>Vendor.</Form.Label>
                    <Form.Control
                      name="vendorNumber"
                      defaultValue={queryParams?.vendorNumber}
                      onChange={(e) => handleFilterParamsChange(e)}
                      placeholder="Input Vendor No."
                    />
                  </Form.Group> */}

                  <Form.Group className="mb-2-5">
                    <Form.Label>Vendor ID</Form.Label>
                    <InputGroup>
                      <Form.Control
                        name="vendorNumber"
                        placeholder="Enter Vendor ID"
                        value={filterParams?.vendorNumber}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                      <Popover
                        isOpen={showItemsPopover}
                        onClickOutside={() => setShowItemsPopover(false)}
                        content={() => (
                          <VendorSelector
                            handleSelectedCustomer={handleSelectedCustomer}
                            setShowCustomerSelector={setShowItemsPopover}
                          />
                        )}
                        position="bottom"
                      >
                        <InputGroup.Text
                          onClick={() => setShowItemsPopover(!showItemsPopover)}
                        >
                          <MagnifyIcon />
                        </InputGroup.Text>
                      </Popover>
                    </InputGroup>
                  </Form.Group>

                  <hr className="my-3" />

                  {/*      <Form.Group className="mb-2-5">
                    <Form.Label>Date</Form.Label>
                    <div>
                      <ReactDatePicker
                        selected={filterParams?.date}
                        onChange={(date) =>
                          setFilterParams({
                            ...filterParams,
                            date,
                          })
                        }
                        customInput={<DatePickerCustomInput />}
                        dateFormat="MMMM dd, yyyy"
                        showPopperArrow={false}
                        placeholderText="Select date"
                      />
                    </div>
                  </Form.Group> */}

                  <Form.Group className="mb-2-5">
                    <Form.Label>Date Range</Form.Label>
                    <div className="position-relative">
                      <RsDateRangePicker
                        placement="auto"
                        value={
                          filterParams.startDate && filterParams.endDate
                            ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                            : []
                        }
                        onClean={() => clearDateRange()}
                        onOk={(date) => filterByDateRange(date)}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="buttons rounded">
                  <Button onClick={() => reset()} type="reset" variant="white">
                    Reset
                  </Button>
                  <Button onClick={() => search()} variant="primary">
                    Search
                  </Button>
                </div>
              </Form>
            </div>
          </div>
          <div className="content-body">
            <header>
              <h1>
                {!showFilter && (
                  <button
                    onClick={() => setShowFilter(!showFilter)}
                    className="btn filter"
                  >
                    <FilterTwoIcon />
                  </button>
                )}
                Purchase History Spreadsheet
                <button
                  title="Refresh"
                  onClick={() => refetch()}
                  className="btn text-primary"
                >
                  <CachedIcon />
                </button>
              </h1>

              <div className="fw-bold">
                <h6>
                  {selectedCustomer
                    ? selectedCustomer?.CompanyName
                    : filterParams?.CompName}
                </h6>
              </div>
              <div className="actions mr-5">
                {/* <button className="btn print">
                  Print <PrintIcon />
                </button>

                <button className="btn print">
                  Export <ExportIcon />
                </button> */}

                {/*  <button className="btn sendSms">
                  Open Ledger <FilePdfIcon />
                </button> */}
                <CSVLink
                  className="btn print d-none"
                  filename={`Purchase History(${format(
                    new Date(),
                    "dd-MMM-yyyy hh:mm:ss a"
                  )}).csv`}
                  data={excelData}
                  ref={CSVLinkRef}
                />

                <Dropdown>
                  <Dropdown.Toggle
                    variant=""
                    className="btn print"
                    disabled={isfetchingExcel}
                    bsPrefix=""
                  >
                    Export
                    <ExportIcon color="#008000" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    popperConfig={{
                      strategy: "fixed",
                    }}
                    renderOnMount
                    className="text-center"
                  >
                    <Dropdown.Item
                      as="div"
                      onClick={onDownloadExcelData}
                      className="p-cursor"
                    >
                      Excel <ExcelIcon color="#008000" />
                    </Dropdown.Item>
                    <Dropdown.Item as="div">
                      <a
                        href={`${backendUrl}/api/reports/pdf/purchase-history?${queryString.stringify(
                          rest
                        )}`}
                        target="blank"
                      >
                        PDF <PDFIcon color="#ff0000" />
                      </a>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <button
                  onClick={() => setBtnSummaryActive(true)}
                  className="btn summaryBtnWhite "
                >
                  Show Summary
                </button>
              </div>
            </header>

            <div className="px-md-4">
              <Table
                responsive
                borderless
                striped
                className="product-table text-nowrap"
              >
                <thead>
                  <tr>
                    <th />
                    <th>S/N</th>
                    <th>Vendor Invoice No</th>
                    <th>Item Code</th>
                    <th>Item Name</th>
                    <th>Vendor</th>
                    <th>QTY Purchased</th>
                    <th>Unit Cost</th>
                    <th>Amount</th>
                    <th>Purchase ID</th>
                    <th>Purchase Date</th>
                    <th>Username</th>
                    <th>Vendor ID</th>

                    <th>Driver Name</th>
                    <th>Truck Number</th>
                    <th>Phone Number</th>
                    <th>Delivery Date</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.purchaseHistory?.map((el, index) => (
                    <tr key={index}>
                      <td>
                        {" "}
                        <Dropdown>
                          <Dropdown.Toggle
                            variant=""
                            className="bg-white border-0"
                            bsPrefix="print more"
                          >
                            <DotsVerticalIcon />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            popperConfig={{
                              strategy: "fixed",
                            }}
                            renderOnMount
                            className="dropdown-with-icons"
                          >
                            <Dropdown.Item
                              as="a"
                              href={
                                el?.type === "warehouse"
                                  ? `${backendUrl}/api/warehouse/pdf/grn/${
                                      el.InvoiceNo
                                    }`
                                  : `${backendUrl}/api/reports/pdf/grn/${
                                      el.InvoiceNo
                                    }`
                              }
                              target="blank"
                            >
                              <RecieptIcon />
                              Print GRN
                            </Dropdown.Item>
                            {isAdmin ? (
                              <>
                                <Dropdown.Item
                                  as="button"
                                  onClick={() => editPurchase(el)}
                                >
                                  <EditIcon />
                                  Edit Purchase
                                </Dropdown.Item>

                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                  onClick={() => deletePurchase(el)}
                                >
                                  <DeleteIcon />
                                  Delete
                                </Dropdown.Item>
                              </>
                            ) : null}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td>{data?.startIndex + index + 1}</td>
                      <td>{el?.InvoiceNo}</td>
                      <td>{el?.Bar_Code}</td>
                      <td>{el.ItemName}</td>
                      <td>{el?.vendor?.CompanyName}</td>
                      <td>
                        <ConvertQuantity
                          quantity={el?.Quantity}
                          desc={el?.item?.Item_Desc}
                        />
                      </td>
                      <td>
                        {currency(el?.UnitPrice, {
                          symbol: "",
                        }).format()}
                      </td>
                      <td>
                        {currency(el?.AmountDue, {
                          symbol: "",
                        }).format()}
                      </td>
                      <td>{el?.PurchaseID}</td>
                      <td>
                        {el?.PurchaseDate
                          ? format(new Date(el?.PurchaseDate), "dd MMM yyyy")
                          : "..."}
                      </td>
                      <td>{el?.Username}</td>
                      <td>{el?.Vendor_ID}</td>
                      <td>{el?.DriverName}</td>
                      <td>{el?.DriverPhoneNumber}</td>
                      <td>{el?.TruckNumber}</td>
                      <td>
                        {" "}
                        {el?.DeliveryDate
                          ? format(new Date(el.DeliveryDate), "dd MMM, yyyy")
                          : "..."}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {!isFetching && isSuccess && isEmpty(data?.purchaseHistory) ? (
                <NoTableItem queryParams={queryParams} />
              ) : null}
            </div>

            {!data?.paginationDisabled ? (
              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>

                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={data.count / queryParams.limit}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>
            ) : (
              <div className="py-2" />
            )}

            <div
              className={
                btnSummryActive
                  ? `summaryNew border-top open`
                  : `summaryNew border-top`
              }
            >
              <div className="summaryHeader">
                <h2>Summary</h2>
                {btnSummryActive && (
                  <button onClick={() => close()} className="btn p-0">
                    <SummaryCloseIcon />
                  </button>
                )}
              </div>

              <div className="gridCont">
                <div className="gridChild gridChildBorderLeftBlue">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.amountDue, {
                        symbol: "₦",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Amount Due</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftOrange">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.amountPaid, {
                        symbol: "₦",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Amount Paid</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftBrown">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.balance, {
                        symbol: "₦",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Balance</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showEditPurchaseHistoryModal && purchaseHistoryToEdit ? (
        <EditPurchaseHistoryModal
          showEditPurchaseHistoryModal={showEditPurchaseHistoryModal}
          setShowEditPurchaseHistoryModal={(show) => {
            setShowEditPurchaseHistoryModal(show);
            if (!show) setPurchaseHistoryToEdit();
          }}
          refetch={refetch}
          purchaseHistoryToEdit={purchaseHistoryToEdit}
          printGRN={printGRN}
        />
      ) : null}
      <ModalLoader
        show={isfetchingExcel || deletePurchaseMutation.isLoading || isLoading}
      />
    </main>

    </IsPrivileged>
  );
}

export default PurchaseHistory;
