import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Logo } from "../Icons";
import NavBar from "../NavBar";

export function NotSubscribed({ text, height = "100vh" }) {
  const navigate = useNavigate();
  return (
    <>
      <div
        className=" h-100 vw-100 d-flex align-items-center justify-content-center text-center "
        style={{ maxHeight: height, height: height }}
      >
        <div>
          <Logo />
          <h4 className="display-5"> {text}</h4>{" "}
          <Button
            onClick={() => navigate(-1)}
            variant=""
            className="text-primary text-underline"
          >
            Go back
          </Button>
        </div>
      </div>
    </>
  );
}
