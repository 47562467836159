import {
  Button,
  Form,
  Table,
  Overlay,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  CalendarIcon,
  ExportIcon,
  FilterCollapseIcon,
  FilterIcon,
  PrintIcon,
  ExcelIcon,
  PDFIcon,
  MoneyDollarIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import CachedIcon from "mdi-react/CachedIcon";
import { useEffect, useState, useRef } from "react";
import {
  FilterTwoIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
} from "../Icons";
import useDebounce, {
  useIsAdmin,
  useIsStore,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { appSettings, services } from "../../config";
import queryString from "query-string";
import { useMutation, useQuery } from "react-query";
import { reportActions } from "../../utils/reactQueryActions";
import { format, parse } from "date-fns";
import {
  customerFullName,
  employeeFullName,
  getStockQuantityAfter,
  getStockQuantityBefore,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
} from "../../utils/helpers";
import ReactPaginate from "react-paginate";
import currency from "currency.js";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import DatePickerCustomInput from "./../utils/DatePickerCustomInput";
import CustomerSelectorPopover from "../utils/CustomerSelectorPopover";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { isEmpty, isNull } from "lodash";
import { useAuth } from "../../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import ItemsTable from "../utils/ItemsTable";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import NoTableItem from "../utils/NoTableItem";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import TableComponent from "../TableComponent";
import { useNavigate } from "react-router-dom";
import ConvertQuantity from "../utils/ConvertQuantity";
import SaleItemsTable from "../utils/SaleItemsTable";
import { UnAuthorized } from "../utils/UnAuthorized";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import CashIcon from "mdi-react/CashIcon";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import { toast } from "react-toastify";
import ConfirmDialog from "../ConfirmDialogue";
import { IsPrivileged } from "../DisplayChildElement";

export default function SalesIncentive() {
  useScrollTop();
  const isAdmin = useIsAdmin();

  const navigate = useNavigate();

  const reportTypeOptions = [
    {
      value: "Detailed",
      label: "Detailed",
    },
    {
      value: "Summary",
      label: "Summary",
    },
    {
      value: "Full",
      label: "Full",
    },
  ];
  const initialFilterParams = {
    reportType: "Detailed",
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    customerId: "",
    //  searchInDate: true,
    isBatchStandard: appSettings.isBatchStandard,
    ProductName: "",
  };

  const isStore = useIsStore();

  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl, isIronRod, isCement } = useAuth();

  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [showCustomerSelector, setShowCustomerSelector] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,

    // Get the date in url and parse so date picker can use it
    date: queryParams?.date
      ? parse(queryParams?.date, "yyyy-MM-dd", new Date())
      : "",
  });

  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchSales = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/sales-analysis?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.items = data.items.map((el) => ({
      label: el.Item_Name,
      value: el.Item_Name,
    }));

    data.branch = [
      {
        label: "All",
        value: "",
      },
      ...data.branch
        .map((el) => ({
          label: el.Branch,
          value: el.Branch,
        }))
        .filter((el) => Boolean(el.value)),
    ];

    data.salesRep = data.salesRep
      .map((el) => ({
        label: employeeFullName(el),
        value: el.EmployeeID,
      }))
      .filter((el) => el.label);

    data.overwriteOfficer = data.overwriteOfficer.map((el) => ({
      label: el.OverwriteOfficer,
      value: el.OverwriteOfficer,
    }));

    data.type = data.type.map((el) => ({
      label: el.Item_Type,
      value: el.Item_Type,
    }));

    data.productName = [
      {
        label: "All",
        value: "",
      },
      ...data.productName
        .map((el) => ({
          label: el.ProductName,
          value: el.ProductName,
        }))
        .filter((el) => el.value),
    ];

    //console.log(data);
    return data;
  };

  const {
    data = {
      count: 0,
      sales: [],
      quantityInStockRemaining: 0,
      productName: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.GET_SALES, queryParams],
    () => fetchSales(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const newQuery = {
    ...rest,
    isCement,
    isAdmin,
    isIronRod,
  };

  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/sales-analysis?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;

    exData =
      queryParams.reportType !== "Summary"
        ? exData.data.sales.map((row) => ({
            ...row,
            Date_Log: format(new Date(row?.Date_Log), "dd-MMM-yyyy hh:mm:ss a"),
          }))
        : exData.data.sales;

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    const Summary = [
      "S/N",
      isCement ? "ATC" : "Item Code",
      "Item Name",
      "QTY Sold",
      "Subtotal",
      isAdmin ? "Profit" : isCement ? "Loading Charge" : "",
      isCement ? "Offloading Charge" : "",
    ];

    const SummaryData = exData.map((d, i) => [
      i + 1,
      d.Bar_Code,
      d.Item_Name,
      isIronRod
        ? qtyFormatToString(
            qtyFormat(d.qtySold, d.Serial_Number, itemMeasurements)
          )
        : d.qtySold,

      currency(d.SubTotal, {
        symbol: "",
      }).format(),
      isAdmin
        ? currency(d.Profit, {
            symbol: "",
          }).format()
        : isCement
        ? currency(d?.deliveryInfo?.LoadingCharge, {
            symbol: "",
          }).format()
        : "",
      isCement
        ? currency(d?.deliveryInfo?.OffloadingCharge, {
            symbol: "",
          }).format()
        : "",
    ]);

    const Full = [
      "S/N",
      "Date",
      isCement ? "ATC" : "Item Code",
      "Description",
      "QTY",
      "Price",
      "Subtotal",
      "Discount",
      "VAT",
      "Amount",
      isAdmin ? "Profit" : "",
      "Valuation",
      "Cost",
      "Sales Type",
      "Cashier",
      "Invoice Number",
      "Manufacturer",
      "Customer",
      "Processby",
      "Orderby",
      isCement ? "Loading Charge" : "",
      isCement ? "Offloading Charge" : "",
    ];

    const FullData = exData.map((d, i) => [
      i + 1,
      d.Date_Log ? format(new Date(d.Date_Log), "yyyy-MM-dd") : "...",
      d.Bar_Code,
      d.Item_Name,
      isIronRod
        ? qtyFormatToString(qtyFormat(d.QTY, d.Serial_Number, itemMeasurements))
        : d.QTY,

      currency(d.PriceSold, {
        symbol: "",
      }).format(),
      currency(d.SubTotal, {
        symbol: "",
      }).format(),

      currency(d.Discount, {
        symbol: "",
      }).format(),
      0,
      currency(d.SubTotal, {
        symbol: "",
      }).format(),
      isAdmin
        ? currency(d.Profit, {
            symbol: "",
          }).format()
        : "",
      currency(d.UnitCost, {
        symbol: "",
      })
        .multiply(d.QTY)
        .format(),
      currency(d.UnitCost, {
        symbol: "",
      }).format(),
      d.OverwriteOfficer,
      d.UserName,
      d.TransactionID,
      d.ProductName,
      customerFullName(d.customer),
      "",
      "",
      isCement
        ? currency(d?.deliveryInfo?.LoadingCharge, {
            symbol: "",
          }).format()
        : "",
      isCement
        ? currency(d?.deliveryInfo?.OffloadingCharge, {
            symbol: "",
          }).format()
        : "",
    ]);

    const Detailed = [
      "Date",
      "Customer",
      "Item Code",
      "Item Name",
      "Quantity",
      "Cost Price",
      isAdmin ? "Profit" : "",
      "Incentive",
      "Selling Price",
    ];

    const DetailedData = exData.map((d) => [
      d.Date_Log ? format(new Date(d.Date_Log), "yyyy-MM-dd") : "...",
      customerFullName(d.customer),
      d.Bar_Code,
      d.Item_Name,
      isIronRod
        ? qtyFormatToString(qtyFormat(d.QTY, d.Serial_Number, itemMeasurements))
        : d.QTY,
      currency(d.UnitCost, {
        symbol: "",
      }).format(),

      isAdmin
        ? currency(d.Profit, {
            symbol: "",
          }).format()
        : "",
      currency(d.Total_Incentive, {
        symbol: "",
      }).format(),
      currency(d.PriceSold, {
        symbol: "",
      }).format(),
    ]);

    const headers =
      queryParams.reportType === "Summary"
        ? Summary
        : queryParams.reportType === "Full"
        ? Full
        : queryParams.reportType === "Detailed"
        ? Detailed
        : [""];

    exData =
      queryParams.reportType === "Summary"
        ? SummaryData
        : queryParams.reportType === "Full"
        ? FullData
        : queryParams.reportType === "Detailed"
        ? DetailedData
        : [""];

    exData = [
      [company],
      ["Incentive Report"],
      [date],
      [""],
      headers,
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Sales",
        currency(data.totalSales, {
          symbol: "",
        }).format(),
      ],
      isAdmin
        ? [
            "Total Profit",
            currency(data.totalProfit, {
              symbol: "",
            }).format(),
          ]
        : [""],
      [
        "Quantity Sold",
        currency(data.qtySold, {
          symbol: "",
          precision: 0,
        }).format(),
      ],
      [
        "Total  Incentive",
        currency(data.totalIncentive, {
          symbol: "",
          precision: 0,
        }).format(),
      ],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      // ...searchParamsToObject(searchQuery.entries()),
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectedCustomer = (customer) => {
    setSelectedCustomer(customer);
    setShowCustomerSelector(false);
    // search({ customerId: customer.Cust_ID });
    setFilterParams({
      ...filterParams,
      customerId: customer.Cust_ID,
    });
  };

  const clearSelectedCustomer = () => {
    // search({ customerId: "" });
    setSelectedCustomer(null);
    setFilterParams({
      ...filterParams,
      customerId: "",
    });
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = (params) => {
    setQueryParams({
      ...queryParams,
      ...filterParams,

      // format the date, before setting it in the url
      date: filterParams?.date
        ? format(new Date(filterParams?.date), "yyyy-MM-dd")
        : "",
      ...params,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedItem = (item) => {
    setFilterParams({
      ...filterParams,
      barcode: item.Bar_Code,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };

  const SummaryTableHead = () => {
    return (
      <thead>
        <tr>
          <th>S/N</th>
          <th>{isCement ? "ATC" : "Item Code"}</th>
          <th>Item Name</th>
          <th>QTY Sold</th>
          <th>Subtotal</th>
          {isAdmin ? <th>Profit</th> : null}
          {/*  <th>Sales Date</th> */}
          {isCement || !appSettings.isBatchStandard ? (
            <>
              <th>Loading Charge</th>
              <th>Offloading Charge</th>
              {isIronRod ? (
                <>
                  <th>Transport Charge</th>
                  <th>Pos Charge</th>
                </>
              ) : null}
            </>
          ) : null}
        </tr>
      </thead>
    );
  };

  const SummaryTableData = (el, index) => {
    return (
      <>
        <td>{data?.startIndex + index + 1}</td>
        <td>{el.Bar_Code}</td>
        <td>{el.Item_Name}</td>

        <td>
          <ConvertQuantity quantity={el.qtySold} desc={el.Serial_Number} />
        </td>
        <td>
          {currency(el.SubTotal, {
            symbol: "",
          }).format()}
        </td>
        {isAdmin ? (
          <td>
            {currency(el.Profit, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {/*  <td>
                      {el.Date_Log
                        ? format(new Date(el.Date_Log), "yyyy-MM-dd")
                        : "..."}
                    </td> */}
        {isCement || !appSettings.isBatchStandard ? (
          <>
            <td>
              {currency(el?.deliveryInfo?.LoadingCharge, {
                symbol: "",
              }).format()}
            </td>
            <td>
              {currency(el?.deliveryInfo?.OffloadingCharge, {
                symbol: "",
              }).format()}
            </td>
            {isIronRod ? (
              <>
                <td>
                  {currency(el?.deliveryInfo?.TransportCharge, {
                    symbol: "",
                  }).format()}
                </td>
                <td>
                  {currency(el?.deliveryInfo?.PosCharge, {
                    symbol: "",
                  }).format()}
                </td>
              </>
            ) : null}
          </>
        ) : null}
      </>
    );
  };

  const FullTableHead = () => {
    return (
      <thead>
        <tr>
          <th>S/N</th>
          <th>Date</th>
          <th>{isCement ? "ATC" : "Item Code"}</th>
          <th>Description</th>
          <th>QTY</th>

          <th>Price</th>
          <th>Subtotal</th>
          <th>Discount</th>
          <th>VAT</th>
          <th>Amount</th>
          {isAdmin ? <th>Profit</th> : null}
          <th>Valuation</th>
          <th>Cost</th>
          <th>Sales Type</th>
          <th>Cashier</th>
          <th>Invoice Number</th>
          <th>Manufacturer</th>
          <th>Business Name</th>
          <th>Processby</th>
          <th>Orderby</th>
          {isCement || !appSettings.isBatchStandard ? (
            <>
              <th>Loading Charge</th>
              <th>Offloading Charge</th>
              {isIronRod ? (
                <>
                  <th>Transport Charge</th>
                  <th>Pos Charge</th>
                </>
              ) : null}
            </>
          ) : null}
        </tr>
      </thead>
    );
  };

  const FullTableData = (el, index) => {
    return (
      <>
        <td>{data?.startIndex + index + 1}</td>
        <td>
          {el.Date_Log ? format(new Date(el.Date_Log), "yyyy-MM-dd") : "..."}
        </td>
        <td>{el.Bar_Code}</td>
        <td>{el.Item_Name}</td>
        <td>
          <ConvertQuantity quantity={el.QTY} desc={el.Serial_Number} />
        </td>

        <td>
          {currency(el.PriceSold, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(el.SubTotal, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(el.Discount, {
            symbol: "",
          }).format()}
        </td>
        <td>{0}</td>
        <td>
          {currency(el.SubTotal, {
            symbol: "",
          }).format()}
        </td>
        {isAdmin ? (
          <td>
            {currency(el.Profit, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        <td>
          {currency(el.UnitCost, {
            symbol: "",
          })
            .multiply(el.QTY)
            .format()}
        </td>
        <td>
          {currency(el.UnitCost, {
            symbol: "",
          }).format()}
        </td>
        <td>{el.OverwriteOfficer}</td>
        <td>{el.UserName}</td>
        <td>{el.TransactionID}</td>
        <td>{el.ProductName}</td>

        <td>
          {" "}
          {el?.customer?.LastName === "Walk-In"
            ? `${el?.transaction?.ShipTo} (Walk-In)`
            : customerFullName(el.customer)}
          {el?.TransactionFrom && ` (${el?.TransactionFrom})`}
        </td>
        <td>{el.UserName}</td>
        <td> </td>
        {isCement || !appSettings.isBatchStandard ? (
          <>
            <td>
              {currency(el?.deliveryInfo?.LoadingCharge, {
                symbol: "",
              }).format()}
            </td>
            <td>
              {currency(el?.deliveryInfo?.OffloadingCharge, {
                symbol: "",
              }).format()}
            </td>
            {isIronRod ? (
              <>
                <td>
                  {currency(el?.deliveryInfo?.TransportCharge, {
                    symbol: "",
                  }).format()}
                </td>
                <td>
                  {currency(el?.deliveryInfo?.PosCharge, {
                    symbol: "",
                  }).format()}
                </td>
              </>
            ) : null}
          </>
        ) : null}
      </>
    );
  };

  const DetailedTableHead = () => {
    return (
      <>
        <thead>
          <tr>
            <th />
            <th>Reconciled</th>
            <th>Incentive Status</th>
            <th>Date</th>
            <th>Business Name</th>
            <th>Item Code</th>
            <th>Item Name</th>
            <th>Quantity</th>
            <th>Cost Price</th>
            {/*    <th colSpan={2}>Margin</th> */}
            <th>Profit</th>
            <th>Incentive</th>
            <th>Selling Price</th>
          </tr>
          {/* <tr>
            <th />
            <th />
            <th />
            <th />
            <th />
            <th>Profit</th>
            <th>Staff Incentive</th>

            <th />
          </tr> */}
        </thead>
      </>
    );
  };

  const DetailedTableData = (el, index) => {
    return (
      <>
        <td>
          <TableDropdown TransactionID={el.TransactionID} />
        </td>
        <td className="fw-bold">
          {el.reconciledFromCustomerLedger ? (
            <span className="text-success">Cleared</span>
          ) : (
            <span className="">Pending</span>
          )}
        </td>
        <td className="fw-bold">
          {el.incentivePaid ? (
            <span className="text-success">Paid</span>
          ) : (
            <span className="">Not Paid</span>
          )}
        </td>
        <td>
          {el.Date_Log ? format(new Date(el.Date_Log), "yyyy-MM-dd") : "..."}
        </td>
        <td>
          {el?.customer?.LastName === "Walk-In"
            ? `${el?.transaction?.ShipTo} (Walk-In)`
            : customerFullName(el.customer)}
          {el?.TransactionFrom && ` (${el?.TransactionFrom})`}
        </td>

        <td>{el.Bar_Code}</td>
        <td>{el.Item_Name}</td>

        <td>
          <ConvertQuantity quantity={el.QTY} desc={el.Serial_Number} />
        </td>
        <td>
          {currency(el.UnitCost, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(el.Profit, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(el.Total_Incentive, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(el.PriceSold, {
            symbol: "",
          }).format()}
        </td>
      </>
    );
  };

  const manageTransaction = (event, el) => {
    if (event.detail === 2 && el.TransactionID) {
      navigate(`/inventory-for-sales/manage-transaction`, {
        state: {
          TransactionID: el.TransactionID,
        },
      });
    }
  };

  const payIncentiveApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/invexcloud/pay-incentive`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const payIncentiveMutation = useMutation(
    (payload) => payIncentiveApi(payload),
    {
      onSuccess: async ({ message, data }) => {
        toast.success(
          `Incentive Paid: ${
            currency(data.incentiveAmountPaid, {
              symbol: "",
            }).value
          }`
        );
        refetch();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const payIncentive = async () => {
    if (
      await ConfirmDialog({
        title: "Pay Incentive",
        description: "Are you sure you want to pay Incentive",
      })
    ) {
      payIncentiveMutation.mutate({});
    }
  };
  // isprivileged
  return !isAdmin ? (
    <UnAuthorized />
  ) : (
    <IsPrivileged roleName="Incentive Report">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-2-5">
                      <Form.Label>Branch</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        name="branch"
                        placeholder="All"
                        theme={reactSelectTheme}
                        isSearchable={true}
                        key={data?.branch}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            branch: selected.value,
                          });
                        }}
                        defaultValue={
                          queryParams?.branch && data?.branch
                            ? data?.branch.find(
                                (el) => el.value === queryParams?.branch
                              )
                            : {
                                value: "",
                                label: "All",
                              }
                        }
                        options={data?.branch}
                      />
                    </Form.Group>

                    {/*   <Form.Group className="mb-2-5">
                    <Form.Label>Sales Rep</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      name="salesRep"
                      placeholder="All"
                      theme={reactSelectTheme}
                      isSearchable={false}
                      key={data?.salesRep}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          salesRep: selected.value,
                        });
                      }}
                      defaultValue={
                        queryParams?.salesRep && data?.salesRep
                          ? data?.salesRep.find(
                              (el) => el.value === queryParams?.salesRep
                            )
                          : {
                              value: "",
                              label: "All",
                            }
                      }
                      options={data?.salesRep}
                    />
                  </Form.Group> */}
                    {/*  <Form.Group className="mb-2-5">
                    <Form.Label>Product / Manufacturer</Form.Label>
                    <Select
                      name="ProductName"
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      placeholder="All"
                      isSearchable={false}
                      key={data?.ProductName}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          ProductName: selected.value,
                        });
                      }}
                      value={
                        data?.productName.find(
                          (el) => el.value === queryParams?.ProductName
                        ) || ""
                      }
                      options={data?.productName || []}
                    />
                  </Form.Group> */}

                    <Form.Group className="mb-2-5">
                      <Form.Label>Sales Type</Form.Label>
                      <Select
                        name="salesType"
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder="All"
                        isSearchable={false}
                        key={data?.overwriteOfficer}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            salesType: selected.value,
                          });
                        }}
                        defaultValue={
                          queryParams?.salesType && data?.overwriteOfficer
                            ? data?.overwriteOfficer.find(
                                (el) => el.value === queryParams?.salesType
                              )
                            : {
                                value: "",
                                label: "All",
                              }
                        }
                        options={data?.overwriteOfficer || []}
                      />
                    </Form.Group>

                    {/*  <Form.Group className="mb-2-5">
                    <Form.Label>Report Type</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      placeholder="All"
                      theme={reactSelectTheme}
                      isSearchable={false}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          reportType: selected.value,
                        });
                      }}
                      defaultValue={
                        queryParams?.reportType
                          ? reportTypeOptions.find(
                              (el) => el.value === queryParams?.reportType
                            )
                          : reportTypeOptions[0]
                      }
                      options={reportTypeOptions}
                    />
                  </Form.Group> */}
                    <hr />
                    <Form.Group className="mb-2-5">
                      <Form.Label>Business Name</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder="All"
                        theme={reactSelectTheme}
                        isSearchable={false}
                        options={[
                          {
                            value: "All Customers",
                            label: "All Customers",
                          },
                          {
                            value: "Selected Customer",
                            label: "Selected Customer",
                          },
                        ]}
                        onChange={(selected) =>
                          selected.value === "Selected Customer"
                            ? setShowCustomerSelector(true)
                            : clearSelectedCustomer()
                        }
                      />
                      <CustomerSelectorPopover
                        showCustomerSelector={showCustomerSelector}
                        setShowCustomerSelector={setShowCustomerSelector}
                        handleSelectedCustomer={handleSelectedCustomer}
                        selectedCustomer={selectedCustomer}
                      />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Type</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        name="type"
                        placeholder="All"
                        theme={reactSelectTheme}
                        isSearchable={true}
                        key={data?.type}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            type: selected.value,
                          });
                        }}
                        defaultValue={
                          queryParams?.type && data?.type
                            ? data?.type.find(
                                (el) => el.value === queryParams?.type
                              )
                            : {
                                value: "",
                                label: "All",
                              }
                        }
                        options={data?.type}
                      />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Code</Form.Label>

                      <InputGroup>
                        <Form.Control
                          name="barcode"
                          onChange={(e) => handleFilterParamsChange(e)}
                          defaultValue={queryParams.barcode}
                          value={filterParams.barcode}
                          placeholder="Input Barcode"
                        />

                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          content={() => (
                            <SaleItemsTable
                              handleSelectedItem={handleSelectedItem}
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() =>
                              setShowItemsPopover(!showItemsPopover)
                            }
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Name</Form.Label>
                      <Form.Control
                        name="itemName"
                        value={filterParams?.itemName}
                        placeholder="Enter Item Name"
                        onChange={(e) => {}}
                      />
                    </Form.Group>

                    <hr />

                    {/*   <Form.Group className="mb-2-5">
                    <Form.Label>Date</Form.Label>
                    <div>
                      <ReactDatePicker
                        selected={filterParams?.date}
                        onChange={(date) =>
                          setFilterParams({
                            ...filterParams,
                            date,
                          })
                        }
                        customInput={<DatePickerCustomInput />}
                        dateFormat="MMMM dd, yyyy"
                        showPopperArrow={false}
                        placeholderText="Select date"
                      />
                    </div>
                  </Form.Group>

                  <div className="mb-2-5">
                    <Form.Check
                      inline
                      label="Search in Date"
                      name="searchInDate"
                      type={"checkbox"}
                      id={`searchInDate`}
                      defaultChecked={JSON.parse(
                        filterParams?.searchInDate || true
                      )}
                      onChange={(e) => handleFilterParamsChange(e)}
                    />
                  </div> */}

                    <Form.Group className="mb-2-5">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="auto"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                  parse(
                                    filterParams.startDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                  parse(
                                    filterParams.endDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header>
                <h1 className="d-flex align-items-center gap-3">
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Sales/Cash Spreadsheet
                  <button
                    title="Refresh"
                    onClick={() => refetch()}
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                  {selectedCustomer && (
                    <>
                      Business Name: <b>{customerFullName(selectedCustomer)}</b>
                    </>
                  )}
                  {queryParams.barcode && data?.itemFromStore ? (
                    <>
                      {data?.itemFromStore.Item_Name}, Quantity in Stock:{" "}
                      <b>
                        {data?.itemFromStore ? (
                          <ConvertQuantity
                            quantity={data.quantityInStockRemaining}
                            desc={data.itemFromStore.Item_Desc}
                          />
                        ) : null}
                      </b>
                    </>
                  ) : null}
                </h1>

                <div className="actions">
                  {/* <a
                  href={`${backendUrl}/api/reports/pdf/sales-analysis?${queryString.stringify(
                    rest
                  )}`}
                  target="blank"
                  className="btn print"
                >
                  Print <PDFIcon color="#ff0000" />
                </a> */}
                  <CSVLink
                    className="btn print d-none"
                    filename={`sales-analysis(${format(
                      new Date(),
                      "dd-MMM-yyyy hh:mm:ss a"
                    )}).csv`}
                    data={excelData}
                    ref={CSVLinkRef}
                  />

                  {/* <button /> */}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      disabled={isfetchingExcel}
                      bsPrefix=""
                    >
                      Export
                      <ExportIcon color="#008000" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item
                        as="div"
                        onClick={onDownloadExcelData}
                        className="p-cursor"
                      >
                        Excel <ExcelIcon color="#008000" />
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/reports/pdf/sales-incentive?${queryString.stringify(
                            newQuery
                          )}`}
                          target="blank"
                        >
                          PDF
                          <PDFIcon color="#ff0000" />
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <button
                    onClick={() => setBtnSummaryActive(!btnSummryActive)}
                    className="btn summaryBtn "
                  >
                    Show Summary
                  </button>
                </div>
              </header>

              <div className="px-md-4">
                <TableComponent
                  responsive
                  borderless
                  striped
                  tableHeadsFunction={
                    queryParams.reportType === "Summary"
                      ? SummaryTableHead
                      : queryParams.reportType === "Full"
                      ? FullTableHead
                      : queryParams.reportType === "Detailed"
                      ? DetailedTableHead
                      : null
                  }
                  mainDataArray={data?.sales}
                  tableDataRowFunction={
                    queryParams.reportType === "Summary"
                      ? SummaryTableData
                      : queryParams.reportType === "Full"
                      ? FullTableData
                      : queryParams.reportType === "Detailed"
                      ? DetailedTableData
                      : null
                  }
                  className="product-table text-nowrap"
                  tableRowClick={(e, el) => manageTransaction(e, el)}
                />

                {!isFetching && isSuccess && isEmpty(data?.sales) ? (
                  <NoTableItem queryParams={queryParams} />
                ) : null}
              </div>

              {!data?.paginationDisabled ? (
                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>

                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={data.count / queryParams.limit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              ) : (
                <div className="py-2" />
              )}

              {/* <div className="p-4 border-top">
          <h2>Summary</h2>
          <div className="summary">
            <table className="table table-borderless balance">
              <tbody>
                <tr>
                  <td>Total Sales</td>
                  <td>218,134,177.92</td>
                </tr>

                <tr>
                  <td>Total Profit</td>
                  <td>9,276,961.63</td>
                </tr>

                <tr>
                  <td>Total Losses</td>
                  <td>N/A</td>
                </tr>

                <tr>
                  <td>Qty Sold</td>
                  <td>19884</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}

              <div
                className={
                  btnSummryActive
                    ? `summaryNew border-top open`
                    : `summaryNew border-top`
                }
              >
                <div className="summaryHeader">
                  <h2>Summary</h2>
                  {btnSummryActive && (
                    <button onClick={() => close()} className="btn p-0">
                      <SummaryCloseIcon />
                    </button>
                  )}
                </div>

                <div className="gridCont">
                  <div className="gridChild gridChildBorderLeftDarkGreen">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data.totalSales, {
                          symbol: "₦",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Sales</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data.totalIncentive, {
                          symbol: "₦",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Incentive</p>
                    </div>
                  </div>

                  {isAdmin && (
                    <div className="gridChild gridChildBorderLeftOrange">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data.totalProfit, {
                            symbol: "₦",
                          }).format()}
                        </p>
                        <p className="gridChld2">Total Profit</p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="gridCont">
                  {/*  INCENTIVE REPORT  */}
                  <div className="gridChild gridChildBorderLeftLightPurple">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data.incentiveEarned, {
                          symbol: "₦",
                        }).format()}
                      </p>
                      <p className="gridChld2">Incentive Earned</p>
                    </div>
                  </div>
                  <div className="gridChild gridChildBorderLeftLightYellow">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data.incentiveDue, {
                          symbol: "₦",
                        }).format()}
                      </p>
                      <p className="gridChld2">Incentive Due</p>
                    </div>
                  </div>
                  <div className="gridChild gridChildBorderLeftDarkerGreen">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data.incentivePaid, {
                          symbol: "₦",
                        }).format()}
                      </p>
                      <p className="gridChld2">Incentive Paid</p>
                    </div>
                  </div>{" "}
                  <div className="p-3">
                    <Button
                      disabled={payIncentiveMutation.isLoading}
                      onClick={() => payIncentive()}
                    >
                      <CashIcon />{" "}
                      {payIncentiveMutation.isLoading
                        ? "Please wait..."
                        : "Pay Incentive"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalLoader show={isfetchingExcel} />
      </main>
    </IsPrivileged>
  );
}

function TableDropdown({ TransactionID }) {
  const navigate = useNavigate();
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          variant=""
          className="bg-white border-0"
          bsPrefix="print more"
        >
          <DotsVerticalIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu
          popperConfig={{
            strategy: "fixed",
          }}
          renderOnMount
          className="dropdown-with-icons"
        >
          <Dropdown.Item
            as="button"
            onClick={() =>
              navigate(`/inventory-for-sales/manage-transaction`, {
                state: {
                  TransactionID,
                },
              })
            }
          >
            <EyeOutlineIcon className="text-light" />
            Open Transaction
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
