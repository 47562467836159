import { Modal, Form, Button } from "react-bootstrap";
import "../../assets/scss/new-entity-modal.scss";
import { useFormik } from "formik";
import { useEffect, useState, useMemo } from "react";
import CurrencyInput from "react-currency-input-field";
import CreatableSelect from "react-select/creatable";
import Datetime from "react-datetime";
import * as yup from "yup";
import Select from "react-select";
import moment from "moment";
// import Datetime from "react-datetime";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
// import moment from "moment";
import { useAuth } from "../../hooks/useAuth";
import {
  fetchActionsUtil,
  sendFormData,
  VendorCat,
  VendorPayment,
} from "../../utils/helpers";
import UploadImage from "./../utils/UploadImage";
import ConfirmDialog from "./../ConfirmDialogue";
import { AdvanceVendor } from "../../config";
import { message } from "antd";
import UploadPDF from "./../utils/UploadPDF";

export default function EditVendorModal({
  showCreateNewVendorModal,
  setShowCreateNewVendorModal,
  setSelectedVendor,
  advance,
}) {
  const { backendUrl } = useAuth();
  const [, setInputValue] = useState("");
  const [jobsValue, setJobsValue] = useState([]);
  const [oldCertNumber, setOldCertNumber] = useState([]);

  const formik = useFormik({
    initialValues: {
      Vendor_ID: "",
      CompanyName: "",
      ContactName: "",
      ContactTitle: "",
      Address: "",
      Phone: "",
      Email: "",
      City: "",
      Vendor_Cat: "",
      toRange: 0,
      fromRange: 0,
      jobs: [],
      AccountNumber: "",
      AccountName: "",
      Bank: "",

      // DateCreated: moment(),
      Certifications: [
        {
          name: "",
          DateObtained: moment(),
          expityDate: moment(),
          certFile: "",
          certName: String(Date.now()),
        },
      ],

      paymentType: "",
      paymentPercent: 0,
      paymentDueDays: "",
    },
    validationSchema: yup.object().shape({
      Vendor_ID: yup.string().required("required"),
      CompanyName: yup.string().required("required"),
      Vendor_Cat: yup.string().required("required"),
      Email: yup.string().required("required"),
    }),
    onSubmit: (values) => {
      const { paymentPercent, paymentType, paymentDueDays } = values;
      // if (typeof DateCreated === "string") {
      //   return formik.setFieldError("DateCreated", "Invalid date");
      // }

      if (paymentType === "Part_Payment") {
        if (
          parseFloat(paymentPercent) > 100 ||
          parseFloat(paymentPercent) < 0
        ) {
          return formik.setFieldError("paymentPercent", "Enter btw 1 - 100");
        }
      }

      if (paymentType === "Post_Payment") {
        if (paymentDueDays === "") {
          return formik.setFieldError("paymentDueDays", "Can not be empty");
        }
      }

      values.jobs = jobsValue.map((d) => d.label);
      values.oldCertNumber = oldCertNumber;
      submit({
        ...values,
      });
    },
  });

  const createVendorMutation = useMutation(
    (payload) => sendFormData(`${backendUrl}/api/vendors/update`, payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        setSelectedVendor("Successfully");
        formik.resetForm();
        setShowCreateNewVendorModal({
          ...showCreateNewVendorModal,
          open: false,
        });
      },
      onError: ({ e, message }) => {
        console.log(e);
        toast.error(message);
      },
    }
  );

  const submit = async (payload) => {
    const { Certifications, jobs, oldCertNumber, ...rest } = payload;

    const formData = new FormData();

    for (const [key, value] of Object.entries(rest)) {
      formData.append(key, value);
    }

    if (jobs.length > 0) {
      formData.append("jobs", JSON.stringify(jobs));
    }

    if (oldCertNumber.length > 0) {
      formData.append("oldCertNumber", JSON.stringify(oldCertNumber));
    }

    const certDatas = [];
    Certifications.forEach((element) => {
      if (element.certFile !== "") {
        formData.append(
          element.certName,
          element.certFile,
          `${element.certName}_certFile`
        );
      }
      if (element.name !== "") {
        const certData = {
          name: element.name,
          DateObtained: element.DateObtained,
          expityDate: element.expityDate,
          certName: element.certName,
        };
        certDatas.push(certData);
      }
    });

    certDatas.length > 0 &&
      formData.append("certData", JSON.stringify(certDatas));

    if (
      await ConfirmDialog({
        title: "Update Vendor",
        description: `Are you sure you want to UPDATE this Account`,
      })
    ) {
      // for (const [key, value] of formData) {
      //   console.log(`${key} => ${value}`);
      // }

      createVendorMutation.mutate(formData);
    }
  };

  const fetchAllData = async (show) => {
    const data = await fetchActionsUtil(
      `${backendUrl}/api/vendors/single/${show.id}`
    );

    setJobsValue(
      data?.vendor?.Vendor_jobs?.map((el) => ({
        label: el.name,
        value: el.name,
      }))
    );

    let { vendorJobs } = await fetchActionsUtil(
      `${backendUrl}/api/vendors/vendor-jobs`,
      "GET"
    );

    vendorJobs = [...new Set(vendorJobs?.map((d) => d.name))].map((el) => ({
      label: el,
      value: el,
    }));

    data.vendorJobs = vendorJobs;

    return data;
  };

  const { data } = useQuery(
    ["GET vendor", showCreateNewVendorModal],
    () => fetchAllData(showCreateNewVendorModal),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    formik.setFieldValue("Vendor_ID", data?.vendor?.Vendor_ID);
    formik.setFieldValue("CompanyName", data?.vendor?.CompanyName);
    formik.setFieldValue("ContactName", data?.vendor?.ContactName);
    formik.setFieldValue("ContactTitle", data?.vendor?.ContactTitle);
    formik.setFieldValue("Address", data?.vendor?.Address);
    formik.setFieldValue("Phone", data?.vendor?.Phone);
    formik.setFieldValue("Email", data?.vendor?.Email);
    formik.setFieldValue("City", data?.vendor?.City);
    formik.setFieldValue("Vendor_Cat", data?.vendor?.Vendor_Cat);
    formik.setFieldValue(
      "toRange",
      data?.vendor?.toRange ? data?.vendor?.toRange : 0
    );
    formik.setFieldValue(
      "fromRange",
      data?.vendor?.fromRange ? data?.vendor?.fromRange : 0
    );
    formik.setFieldValue("AccountNumber", data?.vendor?.AccountNumber);
    formik.setFieldValue("AccountName", data?.vendor?.AccountName);
    formik.setFieldValue("Bank", data?.vendor?.Bank);

    formik.setFieldValue("paymentType", data?.vendor?.paymentType);
    formik.setFieldValue("paymentPercent", data?.vendor?.paymentPercent);
    formik.setFieldValue("paymentDueDays", data?.vendor?.paymentDueDays);
  }, [data]);

  useMemo(async () => {
    try {
      const cert = await Promise.all(
        data?.vendor?.Vendor_files?.map((el) => {
          return new Promise(async (reslove, reject) => {
            const cert = [];
            const certNumber = [];
            try {
              const getPics = await fetch(
                `${backendUrl}/api/vendors/cert/${el.certName}`
              );
              const certFile = await getPics.blob();
              cert.push({ ...el, certFile });
              certNumber.push(el.certName);

              reslove({ cert, certNumber });
            } catch (error) {
              console.log(error);
              cert.push({ ...el, certFile: false });
              reject(cert);
            }
          });
        })
      );

      const certfiles = cert?.map((el) => el.cert[0]);

      if (certfiles.length > 0) {
        formik.setFieldValue("Certifications", certfiles);
        setOldCertNumber(() => cert?.map((el) => el?.certNumber[0]));
      } else {
        formik.setFieldValue("Certifications", [
          {
            name: "",
            DateObtained: moment(),
            expityDate: moment(),
            certFile: "",
            certName: String(Date.now()),
          },
        ]);
      }
    } catch (err) {
      console.log(err);
      console.log("GA: Error in EditVentdorModal");
    }
  }, [data?.vendor?.Vendor_files, backendUrl]);

  const onChangeCertDate = (dates, ind, name) => {
    const newDate = formik.values.Certifications.map((cert, i) => {
      if (ind !== i) return cert;
      const value = dates ? dates : moment();
      return { ...cert, [name]: value };
    });
    formik.setFieldValue("Certifications", newDate);
  };

  const OnChangeInput = (e, ind) => {
    const { name, value, files } = e.target;
    const newData = formik.values.Certifications.map((cert, i) => {
      if (ind !== i) return cert;
      if (name === "certFile") return { ...cert, [name]: files[0] };
      return { ...cert, [name]: value };
    });
    formik.setFieldValue("Certifications", newData);
  };

  const addMoreCert = () => {
    formik.setFieldValue(
      "Certifications",
      formik.values.Certifications.concat({
        name: "",
        DateObtained: moment(),
        expityDate: moment(),
        certFile: "",
        certName: String(Date.now()),
      })
    );
  };

  const removeCert = (idx) => {
    if (formik.values.Certifications.length === 1) return;
    const removecert = formik.values.Certifications.filter((r, i) => i !== idx);
    formik.setFieldValue("Certifications", removecert);
  };

  return (
    <Modal
      show={showCreateNewVendorModal.open}
      onHide={() =>
        setShowCreateNewVendorModal({
          ...showCreateNewVendorModal,
          open: false,
        })
      }
      dialogClassName="item-select-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      className="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Vendor Information</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          className=" pb-2"
          autoComplete="off"
        >
          <div className="">
            <div className="row">
              <div className="col-6">
                <Form.Group className="mb-3 pb-2">
                  <Form.Label className="mb-1">Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Company Name"
                    name="CompanyName"
                    value={formik.values.CompanyName}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.CompanyName && !!formik.errors.CompanyName
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.CompanyName}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-6">
                <Form.Group className="mb-3 pb-2">
                  <Form.Label className="mb-1">Contact Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Contact Name"
                    name="ContactName"
                    value={formik.values.ContactName}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.ContactTitle &&
                      !!formik.errors.ContactTitle
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.ContactTitle}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Email Address</Form.Label>
                <Form.Control
                  className=""
                  type="email"
                  placeholder="Enter email"
                  name="Email"
                  value={formik.values.Email}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.Email && !!formik.errors.Email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Phone Number</Form.Label>
                <Form.Control
                  className=""
                  type="tel"
                  placeholder="(555) xxxx xxxx"
                  name="Phone"
                  value={formik.values.Phone}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.Phone && !!formik.errors.Phone}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Phone}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className="row">
              <div className="col-6">
                <Form.Group className="mb-3 pb-2">
                  <Form.Label className="mb-1">Contact Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Contact Title"
                    name="ContactTitle"
                    value={formik.values.ContactTitle}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.ContactTitle &&
                      !!formik.errors.ContactTitle
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.ContactTitle}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Vendor Category</Form.Label>
                  <Select
                    classNamePrefix="form-select"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    placeholder="All"
                    isSearchable={false}
                    value={VendorCat.find(
                      (d) => d.value === formik.values.Vendor_Cat
                    )}
                    options={VendorCat}
                    onChange={(selected) => {
                      formik.setFieldValue("Vendor_Cat", selected.value);
                    }}
                  />
                  {formik.touched.Vendor_Cat && !!formik.errors.Vendor_Cat ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.Vendor_Cat}
                    </span>
                  ) : null}
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <Form.Group className="col-6 mb-3">
                <Form.Label>Payment Type</Form.Label>
                <Select
                  classNamePrefix="form-select"
                  menuPosition="fixed"
                  menuPlacement="auto"
                  placeholder="Payment Type"
                  isSearchable={false}
                  value={VendorPayment.find(
                    (d) => d.value === formik.values.paymentType
                  )}
                  options={VendorPayment}
                  onChange={(selected) => {
                    formik.setFieldValue("paymentType", selected.value);
                  }}
                />
                {formik.touched.paymentType && !!formik.errors.paymentType ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.paymentType}
                  </span>
                ) : null}
              </Form.Group>

              {formik.values.paymentType === "Part_Payment" && (
                <Form.Group className="col-6 mb-3">
                  <Form.Label>Part Payment Percentage</Form.Label>
                  <Form.Control
                    type="number"
                    className="form-control"
                    value={formik.values.paymentPercent}
                    placeholder="Part Payment Percent"
                    allowNegativeValue={false}
                    allowDecimals={false}
                    name="paymentPercent"
                    onChange={formik.handleChange}
                    max={100}
                    min={1}
                  />
                  {formik.touched.paymentPercent &&
                  !!formik.errors.paymentPercent ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.paymentPercent}
                    </span>
                  ) : null}
                </Form.Group>
              )}

              {formik.values.paymentType === "Post_Payment" && (
                <Form.Group className="col-3 mb-3">
                  <Form.Label>Payment Due Days</Form.Label>
                  <Form.Control
                    type="number"
                    className="form-control"
                    value={formik.values.paymentDueDays}
                    placeholder="Payment Due Days"
                    allowNegativeValue={false}
                    allowDecimals={false}
                    name="paymentDueDays"
                    onChange={formik.handleChange}
                    max={100}
                    min={1}
                  />
                  {formik.touched.paymentDueDays &&
                  !!formik.errors.paymentDueDays ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.paymentDueDays}
                    </span>
                  ) : null}
                </Form.Group>
              )}
            </div>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Vendor Address</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter Vendor address"
                name="Address"
                rows={5}
                value={formik.values.Address}
                onChange={formik.handleChange}
                isInvalid={formik.touched.Address && !!formik.errors.Address}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Address}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="row">
              <div className="col-6">
                <Form.Group className="mb-3 pb-2">
                  <Form.Label className="mb-1">City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter City"
                    name="City"
                    value={formik.values.City}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.City && !!formik.errors.City}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.City}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              {AdvanceVendor && (
                <div className="col-6">
                  <Form.Group className="mb-3 pb-2">
                    <div className="text-center">
                      <Form.Label className="mb-1">Cost Range</Form.Label>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <CurrencyInput
                          className="form-control"
                          value={formik.values.fromRange}
                          placeholder="Enter Start Range"
                          allowNegativeValue={false}
                          allowDecimals={false}
                          name="fromRange"
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                        />
                      </div>

                      <div className="col-6">
                        <CurrencyInput
                          className="form-control"
                          value={formik.values.toRange}
                          placeholder="Enter End Range"
                          allowNegativeValue={false}
                          allowDecimals={false}
                          name="toRange"
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                        />
                      </div>
                    </div>
                  </Form.Group>
                </div>
              )}
            </div>
            {/*  */}
            <div className="row">
              <div className="col-6">
                <Form.Group className="mb-3 pb-2">
                  <Form.Label className="mb-1">Account Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Account Name"
                    name="AccountName"
                    value={formik.values.AccountName}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.AccountName && !!formik.errors.AccountName
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.AccountName}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-6">
                <Form.Group className="mb-3 pb-2">
                  <div className="d-flex justify-content-between">
                    <Form.Label>Bank</Form.Label>
                    <Form.Label>Account Number</Form.Label>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <Form.Control
                        type="text"
                        placeholder="Enter Bank"
                        name="Bank"
                        value={formik.values.Bank}
                        onChange={formik.handleChange}
                        isInvalid={formik.touched.Bank && !!formik.errors.Bank}
                      />
                    </div>

                    <div className="col-6">
                      <Form.Control
                        type="text"
                        placeholder="Enter Account Number"
                        name="AccountNumber"
                        value={formik.values.AccountNumber}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.AccountNumber &&
                          !!formik.errors.AccountNumber
                        }
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>

            {/*  */}

            {AdvanceVendor && (
              <>
                <div className="my-3">
                  <Form.Label className="mb-1">Products/Services</Form.Label>
                  <CreatableSelect
                    isMulti
                    isClearable
                    onChange={(newValue) => setJobsValue(newValue)}
                    onInputChange={(newValue) => setInputValue(newValue)}
                    options={data?.vendorJobs}
                    value={jobsValue}
                  />
                </div>

                <div className="text-center">
                  <Form.Label className="mt-2">Certifications</Form.Label>
                </div>

                {formik.values.Certifications?.length > 0 &&
                  formik.values.Certifications.map((cert, inxd) => {
                    return (
                      <div className="mb-3 border rounded-1 p-4 position-relative">
                        <span
                          className="position-absolute top-0 start-100 translate-middle badge rounded bg-danger p-cursor"
                          onClick={() => removeCert(inxd)}
                        >
                          X
                        </span>
                        <div>
                          <Form.Label className="mb-1">
                            Certificate Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Certificate Name"
                            name="name"
                            value={cert.name}
                            onChange={(e) => OnChangeInput(e, inxd)}
                          />
                        </div>
                        <div className="row">
                          <div className="col-6 my-3">
                            <Form.Label className="mb-1">
                              Date Obtained
                            </Form.Label>
                            <Datetime
                              dateFormat="MMM DD, YYYY"
                              timeFormat={false}
                              closeOnSelect={true}
                              closeOnClickOutside={true}
                              name="DateObtained"
                              inputProps={{
                                className: `date-input form-control`,
                                placeholder: "Select date",
                                readOnly: true,
                              }}
                              value={moment(cert.DateObtained).format(
                                "MMM DD, YYYY"
                              )}
                              onChange={(date) => {
                                onChangeCertDate(date, inxd, "DateObtained");
                              }}
                            />
                          </div>
                          <div className="col-6 my-3">
                            <Form.Label className="mb-1">
                              Expiry Date
                            </Form.Label>
                            <Datetime
                              dateFormat="MMM DD, YYYY"
                              timeFormat={false}
                              closeOnSelect={true}
                              closeOnClickOutside={true}
                              name="expityDate"
                              inputProps={{
                                className: `date-input form-control`,
                                placeholder: "Select date",
                                readOnly: true,
                              }}
                              value={moment(cert.expityDate).format(
                                "MMM DD, YYYY"
                              )}
                              onChange={(date) => {
                                onChangeCertDate(date, inxd, "expityDate");
                              }}
                            />
                          </div>
                          <Form.Label className="mb-1">
                            Upload Certificate
                          </Form.Label>
                          {/* <UploadImage
                            onFileUpload={(e) => OnChangeInput(e, inxd)}
                            name="certFile"
                            image={cert.certFile}
                            size="w-50 h-50"
                          /> */}
                          <UploadPDF
                            onFileUpload={(e) => OnChangeInput(e, inxd)}
                            name="certFile"
                            image={cert.certFile}
                            // clearImage={clearImage}
                            size="w-30 h-30"
                          />
                        </div>
                      </div>
                    );
                  })}

                <div className="my-3 text-primary fw-bold fs-5">
                  <button
                    type="button"
                    className="badge bg-primary"
                    onClick={addMoreCert}
                  >
                    +Add More
                  </button>
                </div>
              </>
            )}
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          type="submit"
          className="submit-btn mt-3 py-2"
          disabled={createVendorMutation.isLoading}
          onClick={() => formik.submitForm()}
        >
          {createVendorMutation.isLoading ? "Please wait..." : "Update Vendor"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
