import Select from "react-select";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import { useFormik } from "formik";
import { Button, Table, Form } from "react-bootstrap";
import { useIsCashier } from "../../utils/hooks";
import * as yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { useEffect, useMemo, useState } from "react";
import currency from "currency.js";
import { useNavigate } from "react-router-dom";
import ModalLoader from "../utils/ModalLoader";
import {
  CashSelectIcon,
  ChequeSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  DropdownCloseIcon,
} from "../Icons";
import { last, isEmpty, lowerCase } from "lodash";
import { queryActions } from "../../utils/reactQueryActions";
import PaymentAccountDialog from "../PaymentAccountDialog";
import ConfirmDialog from "../ConfirmDialogue";
import { formatDate, resolveApprovalBadgeBg } from "../../utils/helpers";
import ApprovalBadge from "../utils/ApprovalBadge";

const paymentTypesForFundRequest = [
  {
    label: "Advanced",
    value: "Advance",
  },
  {
    label: "Direct",
    value: "Direct",
  },
];
const paymentMethod = [
  {
    icon: <CreditSelectIcon />,
    label: "Imprest",
    value: "Imprest",
  },
  {
    icon: <CashSelectIcon />,
    label: "Cash",
    value: "Cash",
  },

  {
    icon: <ChequeSelectIcon />,
    label: "Cheque",
    value: "Cheque",
  },

  {
    icon: <DirectTransferSelectIcon />,
    label: "Direct Bank Transfer",
    value: "Direct Bank Transfer",
  },
  {
    icon: <CreditSelectIcon />,
    label: "Credit/Debit Card (POS)",
    value: "Credit/Debit Card",
  },
];

const cashOptions = [
  {
    label: "Cash On Hand",
    value: "Cash On Hand",
  },
];

function Attachments({ files = [], setFiles }) {
  //  const [files, setFiles] = useState([]);
  const { backendUrl } = useAuth();
  return (
    <div>
      <Form.Label className="btn btn-light-blue">
        <span>Add Files </span>
        <Form.Control
          type="file"
          className="d-none"
          multiple
          onChange={(e) => setFiles([...files, ...e.target.files])}
        />
      </Form.Label>

      <div>
        {files?.map((el, index) => (
          <div key={index}>
            {el?.fileId ? (
              <a
                href={`${backendUrl}/api/requisition/files/${
                  el.systemFileName
                }/${el.name}`}
                target="_blank"
                rel="noreferrer"
              >
                {el?.name}
              </a>
            ) : (
              <span>{el?.name}</span>
            )}

            <Button
              variant=""
              size="sm"
              onClick={(e) => setFiles(files.filter((el, i) => i !== index))}
            >
              <DropdownCloseIcon />
            </Button>
            <hr className="m-0" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default function FundRequestForm({
  requisition,
  tableData,
  total,
  refetch,
  fundRequest,
  currencySymbol,
}) {
  const isCashier = useIsCashier();
  const { backendUrl, token, user: authUser } = useAuth();
  const navigate = useNavigate();

  const postRequisitionData = async (payload) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      if (key === "files") {
        for (const file of value) {
          //  console.log(Boolean(!file?.fileId));
          if (!file?.fileId) {
            formData.append("files", file);
          }
        }
        continue;
      }
      formData.append(key, value);
    }
    formData.append(
      "alreadyUploadedFiles",
      JSON.stringify(payload.files.filter((el) => el.fileId))
    );
    let response = await fetch(
      `${backendUrl}/api/requisition/update-data/${requisition.requestid}`,
      {
        method: "POST",
        credentials: "include",
        body: formData,
        headers: {
          /*   Accept: "Application/json",
          "Content-Type": "Application/json", */
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const postMutation = useMutation((payload) => postRequisitionData(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
    onSettled: () => {
      formik.setFieldValue("retireNow", false);
    },
  });

  const closeRequisitionData = async (payload) => {
    // console.log(payload);
    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      formData.append(key, value);
    }
    let response = await fetch(
      `${backendUrl}/api/requisition/update-sub/${requisition.requestid}`,
      {
        method: "POST",
        credentials: "include",
        body: formData,
        headers: {
          /*   Accept: "Application/json",
          "Content-Type": "Application/json", */
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const closeOutMutation = useMutation(
    (payload) => closeRequisitionData(payload),
    {
      onSuccess: ({ message }) => {
        // toast.success(message);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const postExpense = () => {
    navigate("/expenses-entry", {
      state: {
        expenses: tableData.map((el) => ({
          ...el,
          Particulers: el.description,
          Amount: currency(el?.unitCost, {
            symbol: "",
            separator: "",
          })
            .multiply(el?.quantity)
            .format(),
        })),
        job: requisition.job,
        Vendor_ID: requisition.vendor
          ? requisition.vendor.split(" / ").pop()
          : "",
        requisitionApprovedBy: last(requisition?.requisitiontracks)
          .receivedbyUser.Name,
        requisitionTitle: requisition?.title,
        currency: requisition.currency,
        requestid: requisition.requestid,
        ...(requisition?.paymentType === "Advance"
          ? { paymentType: requisition.paymentType }
          : {}),
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      By: "",
      From: "",
      initialAdvancePayment: 0,
      paymentType: "",
      amountAdvanced: 0,
      amountExpensed: "",
      files: [],
      //fundRequestRemark: "",
      /* reimburseBy: "Imprest",
      reimburseFrom: "", */

      retireNow: false,
    },
    validationSchema: yup.object().shape({
      paymentType: yup.string().required("required"),
      initialAdvancePayment: yup.number().required("required"),
      //  .moreThan(0, "must be greater than 0"),
    }),
    onSubmit: (values) => {
      // Advanced Payment Validation - First time only
      const errors = {};
      if (
        isEmpty(requisition.paymentType) &&
        values.paymentType === "Advance"
      ) {
        if (isEmpty(values.By)) {
          errors.By = "Required";
        }
        if (isEmpty(values.From)) {
          errors.From = "Required";
        }
        if (
          isEmpty(values.initialAdvancePayment) ||
          Number(values.initialAdvancePayment) == 0
        ) {
          errors.initialAdvancePayment = "Required";
        }
      }

      if (!isEmpty(errors)) {
        formik.setErrors(errors);
        return;
      }

      if (
        values.paymentType !== "Direct" &&
        requisition?.initialAdvancePayment &&
        Number(values.amountExpensed) !==
          Number(requisition?.initialExpenseAmount) &&
        Number(total.value) !== Number(values.amountExpensed)
      ) {
        formik.setFieldError(
          "amountExpensed",
          //"Amount Advanced and initial Expense do not match.\n please adjust items to match  Amount Advanced"
          "Amount Expensed does not match items in requisition. \n Please adjust items to match"
        );
        //  toast.error("...");
      } else {
        values.amountAdvanced = values.initialAdvancePayment;

        //-------

        let description = [
          ...tableData,
          ...(fundRequest?.description ? [fundRequest] : []),
        ]
          .map((el) => {
            delete el.subtotal;
            return Object.values(el).join(",,,");
          })
          .join("&&&");

        description = description + "&&&";

        //-------
        postMutation.mutate(
          {
            ...values,
            reimburseToCompany,
            reimburseToEmployee,

            description,
            //  amount: total,
            ...(requisition?.status === "Pending Payment" &&
            values.paymentType === "Advance"
              ? { status: "Pending Retirement" }
              : requisition?.status === "Pending Retirement" &&
                values.paymentType === "Advance" &&
                (Number(reimburseToCompany) > 0 ||
                  Number(reimburseToEmployee) > 0)
              ? { status: "Pending Reimbursement" }
              : {}),

            ...(isEmpty(requisition?.By)
              ? {
                  By: values.By,
                  From: values.From,
                  postAmount: true,
                }
              : {
                  By: values.By,
                  From: values.From,
                }),
          },
          {
            onSuccess: () => {
              if (
                values.paymentType === "Direct" &&
                [
                  "Approved & Closed",
                  "Pending Payment",
                  "Pending Reimbursement",
                ].includes(requisition?.status)
              ) {
                postExpense();
              } else {
                refetch();
              }
            },
          }
        );
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      initialAdvancePayment: requisition.initialAdvancePayment || 0,
      paymentType: requisition.paymentType,
      amountAdvanced: requisition.amountAdvanced || 0,
      amountExpensed: requisition.amountExpensed || 0,
      files: requisition?.files ? JSON.parse(requisition?.files) : [],
      By: requisition.By,
      From: requisition.From,
    });
  }, [
    requisition?.initialAdvancePayment,
    requisition.paymentType,
    requisition.amountAdvanced,
    requisition.amountExpensed,
    requisition.files,
    requisition.By,
    requisition.From,
    // formik,
  ]);

  const reimburseToCompany = useMemo(() => {
    return Number(formik.values.amountAdvanced) >
      Number(formik.values.amountExpensed)
      ? currency(formik.values.amountAdvanced).subtract(
          formik.values.amountExpensed
        ).value
      : 0;
  }, [formik.values.amountAdvanced, formik.values.amountExpensed]);

  const reimburseToEmployee = useMemo(() => {
    return Number(formik.values.amountExpensed) >
      Number(formik.values.amountAdvanced)
      ? currency(formik.values.amountExpensed).subtract(
          formik.values.amountAdvanced
        ).value
      : 0;
  }, [formik.values.amountAdvanced, formik.values.amountExpensed]);
  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/invoice/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.vendors = [
      {
        value: "",
        label: "One-time vendor",
      },
      ...data?.vendors.map((el) => ({
        label: el.CompanyName,
        value: el.Vendor_ID,
      })),
    ];

    data.banks = data?.banks
      .filter((el) => el?.BankName)
      .map((el) => ({
        label: el.BankName,
        value: el.BankName,
      }));

    data.branch = [
      {
        value: "General",
        label: "General",
      },
    ];

    data.imprest = data?.imprest
      .filter((el) => el?.Description)
      .map((el) => ({
        ...el,
        label: el?.Description,
        value: el?.Description,
      }));

    if (!isEmpty(data?.imprest)) {
      //  formik.setFieldValue("From", data.imprest[0].value);
    }
    return data;
  };

  const {
    data = { banks: [], vendors: [], branch: [], imprest: [] },
    isLoading,
  } = useQuery([queryActions.INVOICE_SETUP], () => fetchSetUpData(), {
    keepPreviousData: true,
  });

  const handlePaymentMethod = (value) => {
    formik.setFieldValue("By", value);
    formik.setFieldValue("From", "");
  };

  const handlePaymentMethodReimburse = (value) => {
    formik.setFieldValue("reimburseByBy", value);
    formik.setFieldValue("reimburseByFrom", "");
  };

  const resolveFromOptions = (PaymentType) => {
    // console.log(PaymentType, data.banks);
    return PaymentType === "Imprest"
      ? data.imprest
      : ["Cheque", "Direct Bank Transfer", "Credit/Debit Card"].includes(
          PaymentType
        )
      ? data.banks
      : cashOptions;
  };

  const closeOut = async () => {
    const reimburseTo =
      reimburseToCompany > reimburseToEmployee ? "Company" : "Staff";
    const formValues = await PaymentAccountDialog({
      data,
      previouslySelectedAccount: {
        By: requisition?.By,
        From: requisition?.From,

        // Remove amount that was settled in payroll generation
        amount: currency(
          reimburseTo === "Company" ? reimburseToCompany : reimburseToEmployee
        ).subtract(requisition?.settledAmount).value,
        reimburseTo,
      },
    });

    if (formValues) {
      //  console.log(formValues);

      closeOutMutation.mutate(
        {
          ...formValues,
          reimburseTo,
          subStatus: "Closed Out",
        },
        {
          onSuccess: ({ message }) => {
            toast.success("Closed Out");
            refetch();
          },
        }
      );
    }
  };

  const retire = async () => {
    formik.setFieldValue("retireNow", true);
    if (
      await ConfirmDialog({
        title: "Retirement",
        description: "Are you sure you want to save?",
      })
    ) {
      formik.submitForm();
    } else {
      formik.setFieldValue("retireNow", false);
    }
  };

  const hasAnyMandatoryUserApproved = (requisition) => {
    let permissions = JSON.parse(requisition.requiredSignatoriesStaff_ID);
    const usersInTracks = requisition?.requisitiontracks
      ? requisition?.requisitiontracks
          .filter(
            (el) => !["pending", "disapproved"].includes(lowerCase(el.status))
          )
          ?.map((el) =>
            [el.sentbyUser?.Staff_ID, el.receivedbyUser?.Staff_ID].filter(
              (el) => el
            )
          ) //  Remove  Sender

          .flat()
          .filter((el) => el !== requisition.requestby)
      : [];

    //  console.log("ssssss", usersInTracks, permissions, requisition.requestby);

    const foundRequiredSignatory = permissions?.find((el) =>
      usersInTracks.includes(el.staff)
    );

    return foundRequiredSignatory;
  };

  return (
    <>
      <Form
        noValidate
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        className="p-3 my-4 border"
      >
        <Table borderless className="upsert-table">
          {/* <thead>
    <tr>
      <th colSpan={2}>Retirement:</th>
    </tr>
  </thead> */}
          <tbody>
            <tr>
              <td className="fw-bold  text-right">Initial Total Expense</td>
              <td>
                <CurrencyCustomInput
                  currencySymbol={currencySymbol}
                  value={requisition?.initialExpenseAmount}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <td className="fw-bold  text-right">Payment Type</td>
              <td>
                <Select
                  classNamePrefix={`form-select`}
                  options={paymentTypesForFundRequest}
                  value={paymentTypesForFundRequest.find(
                    (el) => el.value === formik.values.paymentType
                  )}
                  onChange={(selected) =>
                    formik.setFieldValue("paymentType", selected?.value)
                  }
                  isDisabled={!isCashier}
                />
                {formik.touched.paymentType && formik.errors.paymentType ? (
                  <span className="text-danger mt-2">
                    {formik.errors.paymentType}
                  </span>
                ) : null}
              </td>
            </tr>
            {formik.values.paymentType === "Direct" && (
              <>
                <tr>
                  <td className="fw-bold  text-right">Attachments</td>
                  <td>
                    <Attachments
                      files={formik.values.files}
                      setFiles={(files) => formik.setFieldValue("files", files)}
                    />
                  </td>
                </tr>
              </>
            )}
            {formik.values.paymentType === "Advance" && (
              <>
                <tr>
                  <td className="fw-bold  text-right">
                    {requisition?.initialAdvancePayment &&
                    requisition?.paymentType === "Advance"
                      ? "Initial Advanced Amount "
                      : `Advanced Amount `}{" "}
                  </td>
                  <td>
                    <CurrencyCustomInput
                      currencySymbol={currencySymbol}
                      readOnly={!isCashier}
                      name="initialAdvancePayment"
                      placeholder="0.00"
                      value={formik.values.initialAdvancePayment}
                      onValueChange={(value, name) => {
                        formik.setFieldValue(name, value);
                        /*  if (requisition?.paymentType === "Advance") {
                        formik.setFieldValue("amountAdvanced", value);
                      } */
                      }}
                    />
                    {formik.touched.initialAdvancePayment &&
                    !!formik.errors.initialAdvancePayment ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.initialAdvancePayment}
                      </span>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={{ width: "15rem" }}>
                      <Form.Group>
                        <Form.Label className="fw-bold ">Pay By</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          menuPlacement="top"
                          placeholder="Choose method"
                          isSearchable={false}
                          value={paymentMethod.find(
                            (el) => el.value === formik.values.By
                          )}
                          options={paymentMethod}
                          onChange={({ value }) => handlePaymentMethod(value)}
                          getOptionLabel={(el) => (
                            <div className="label-with-icon d-flex gap-2 align-items-center">
                              <span>{el.icon}</span>{" "}
                              <span className="fw-5">{el.label}</span>
                            </div>
                          )}
                          //  menuIsOpen={true}
                        />
                        {formik.touched.By && !!formik.errors.By ? (
                          <span className="custom-invalid-feedback">
                            {formik.errors.By}
                          </span>
                        ) : null}
                      </Form.Group>
                    </div>
                  </td>{" "}
                  <td>
                    {" "}
                    <Form.Group>
                      <Form.Label className="fw-bold ">Pay From</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        value={resolveFromOptions(formik.values.By).find(
                          (el) => el.value === formik.values.From
                        )}
                        options={resolveFromOptions(formik.values.By)}
                        onChange={({ value }) =>
                          formik.setFieldValue("From", value)
                        }
                        key={formik.values?.By}
                      />
                      {formik.touched.From && !!formik.errors.From ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.From}
                        </span>
                      ) : null}
                    </Form.Group>
                  </td>
                </tr>

                {/*   <tr>
                  <td>
                    <div style={{ width: "15rem" }}>
                      <Form.Group>
                        <Form.Label className="fw-bold ">
                          Reimburse By
                        </Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          menuPlacement="top"
                          placeholder="Choose method"
                          isSearchable={false}
                          value={paymentMethod.find(
                            (el) => el.value === formik.values.reimburseBy
                          )}
                          options={paymentMethod}
                          onChange={({ value }) =>
                            handlePaymentMethodReimburse(value)
                          }
                          getOptionLabel={(el) => (
                            <div className="label-with-icon d-flex gap-2 align-items-center">
                              <span>{el.icon}</span>{" "}
                              <span className="fw-5">{el.label}</span>
                            </div>
                          )}
                          //  menuIsOpen={true}
                        />
                      </Form.Group>
                    </div>
                  </td>{" "}
                  <td>
                    {" "}
                    <Form.Group>
                      <Form.Label className="fw-bold ">
                        {" "}
                        Reimburse From
                      </Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        value={resolveFromOptions(
                          formik.values.reimburseBy
                        ).find(
                          (el) => el.value === formik.values.reimburseFrom
                        )}
                        options={resolveFromOptions(formik.values.reimburseBy)}
                        onChange={({ value }) =>
                          formik.setFieldValue("reimburseFrom", value)
                        }
                        key={formik.values?.reimburseBy}
                      />
                      {formik.touched.reimburseFrom &&
                      !!formik.errors.reimburseFrom ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.reimburseFrom}
                        </span>
                      ) : null}
                    </Form.Group>
                  </td>
                </tr> */}
              </>
            )}
            {requisition?.paymentType === "Advance" ? (
              <>
                <tr>
                  <td colSpan={2}>
                    {" "}
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <h2>Retirement</h2>
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold  text-right">Amount Advanced</td>
                  <td>
                    <CurrencyCustomInput
                      //readOnly={authUser.Staff_ID !== requisition?.requestby}\
                      currencySymbol={currencySymbol}
                      readOnly={true}
                      name="amountAdvanced"
                      placeholder="0.00"
                      value={formik.values.amountAdvanced}
                      onValueChange={(value, name) => {
                        formik.setFieldValue(name, value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold  text-right">Amount Expensed</td>
                  <td>
                    <CurrencyCustomInput
                      currencySymbol={currencySymbol}
                      readOnly={authUser.Staff_ID !== requisition?.requestby}
                      name="amountExpensed"
                      placeholder="0.00"
                      value={formik.values.amountExpensed}
                      onValueChange={(value, name) => {
                        formik.setFieldValue(name, value);
                      }}
                    />
                    {formik.touched.amountExpensed &&
                    !!formik.errors.amountExpensed ? (
                      <span
                        className="custom-invalid-feedback  d-block mt-1"
                        style={{ width: "16rem" }}
                      >
                        {formik.errors.amountExpensed}
                      </span>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold  text-right">
                    Amount Reimbursable to Company
                  </td>
                  <td>
                    <CurrencyCustomInput
                      currencySymbol={currencySymbol}
                      readOnly
                      value={reimburseToCompany}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold  text-right">
                    Amount Reimbursable to Employee
                  </td>
                  <td>
                    <CurrencyCustomInput
                      currencySymbol={currencySymbol}
                      readOnly
                      value={reimburseToEmployee}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold  text-right">Attachments</td>
                  <td>
                    <Attachments
                      files={formik.values.files}
                      setFiles={(files) => formik.setFieldValue("files", files)}
                    />
                  </td>
                </tr>
                {requisition?.retirementDate ? (
                  <tr>
                    <td className="fw-bold  text-right">Retirement Date</td>
                    <td>{formatDate(requisition?.retirementDate)}</td>
                  </tr>
                ) : null}
                {requisition?.reimbursementDueDate &&
                requisition.reimburseToCompany ? (
                  <tr>
                    <td className="fw-bold  text-right">
                      Reimbursement Due Date
                    </td>
                    <td className="d-flex gap-3">
                      <span className="text-nowrap">
                        {" "}
                        {formatDate(requisition?.reimbursementDueDate)}{" "}
                      </span>
                      {requisition?.subStatus && (
                        <ApprovalBadge
                          text={requisition.subStatus}
                          className="approval"
                          bg={resolveApprovalBadgeBg(requisition.subStatus)}
                        />
                      )}
                      {requisition?.settledAmount &&
                      Number(requisition?.settledAmount) > 0 ? (
                        <i>
                          {currency(requisition?.settledAmount, {
                            symbol: "",
                          }).format()}{" "}
                          was settled from Payroll
                        </i>
                      ) : null}
                    </td>
                  </tr>
                ) : null}
              </>
            ) : null}
          </tbody>
        </Table>

        <div className="d-flex gap-3">
          {isEmpty(requisition?.paymentType) &&
          isCashier &&
          hasAnyMandatoryUserApproved(requisition) ? (
            <Button variant="primary" className="" type="submit">
              {formik.values.paymentType === "Direct" ? "Authorize" : "Save"}
            </Button>
          ) : null}

          {!hasAnyMandatoryUserApproved(requisition) && (
            <i>No Mandory Signatory has Approved</i>
          )}

          <>
            {requisition.paymentType === "Direct" && (
              <>
                {isCashier &&
                requisition?.status !== "Approved & Closed - Paid" &&
                hasAnyMandatoryUserApproved(requisition) ? (
                  <Button variant="primary" className="" type="submit">
                    Post Expense
                  </Button>
                ) : null}

                {requisition?.status === "Pending" && isCashier ? (
                  <Button variant="primary" className="" type="submit">
                    Authorize
                  </Button>
                ) : null}
              </>
            )}
          </>

          {/*   */}
          {requisition.paymentType === "Advance" && (
            <>
              {/* Creator  */}
              {requisition?.initialAdvancePayment &&
              authUser.Staff_ID === requisition?.requestby &&
              !["Approved & Closed", "Approved & Closed - Paid"].includes(
                requisition?.status
              ) &&
              !requisition?.settledAmount ? (
                <Button
                  variant="primary"
                  className=""
                  type="button"
                  title={authUser.Staff_ID}
                  onClick={() => retire()}
                >
                  Save
                </Button>
              ) : null}

              {/* Cashier  */}

              {isCashier &&
              requisition?.retirementDate !== null &&
              requisition?.subStatus !== "Closed Out" ? (
                <Button
                  variant="light-blue"
                  className=""
                  type="button"
                  onClick={() => closeOut()}
                >
                  Close Out
                </Button>
              ) : null}

              {isCashier &&
              requisition?.status !== "Approved & Closed - Paid" &&
              hasAnyMandatoryUserApproved(requisition) &&
              requisition?.retirementDate !== null ? (
                <Button
                  variant="primary"
                  className=""
                  type="button"
                  onClick={() => postExpense()}
                >
                  Post Expense
                </Button>
              ) : null}
            </>
          )}
        </div>
      </Form>
      <ModalLoader
        show={postMutation.isLoading || closeOutMutation.isLoading}
      />
    </>
  );
}
