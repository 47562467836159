import { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { useIsAdmin, useIsDocumentControl, useIsQHSE } from "../../utils/hooks";

export default function RequisitionNav(props) {
  const isAdmin = useIsAdmin();
  const isDocumentControl = useIsDocumentControl();
  const isQhse = useIsQHSE();
  const items = useMemo(
    () => [
      ...(isAdmin || isDocumentControl || isQhse
        ? [
            {
              name: "Forms",
              to: "forms",
            },
          ]
        : []),
      {
        name: "Request",
        to: "request",
      },
      ...(isAdmin
        ? [
            {
              name: "Fund Request Approval",
              to:
                "fund-request-approval?page=1&limit=40&q=&status=&type=Fund+Request&sortByDate=dateModified&FormID=&showAll=true",
            },
          ]
        : []),
      {
        name: "Fill a Form",
        to: "fill-a-form",
      },
    ],
    [isAdmin]
  );

  return (
    <div className="dashboard-tabs innerpage-tabs px-4">
      <ul className="nav nav-tabs">
        {items.map((item, index) => (
          <li key={index} className="nav-item">
            <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
